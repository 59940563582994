import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomColorPaletteService {
  COLOR_CUSTOM = '#504d8c';
  COLOR_WHITE = '#F2F2F2';
  COLOR_BLACK = '#222222';
  COLOR_TEXT = '#080808';

  constructor() {}

  public getPrimaryColor(): string {
    return window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--primary-40')
      .replace('#', '')
      .trim();
  }

  public defineColors(custom: string, white: string, black: string): void {
    this.COLOR_CUSTOM = custom;
    this.COLOR_WHITE = white;
    this.COLOR_BLACK = black;
  }

  public hexColorTestValidator(hexadecimalColor: string): boolean {
    const pattern = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
    return pattern.test(hexadecimalColor);
  }

  public buildColorPalette(): void {
    const baseColor = this.COLOR_CUSTOM; //localStorage.getItem('mngs-color');

    const primaryVariable = '--primary-';

    for (let index = 10; index <= 90; index += 10) {
      let value = index;

      // if (this.themeService.getSelectedTheme() === 'dark') {
      //   value = 100 - index;
      //   // INVERTER CORES
      //   document.documentElement.style.setProperty('--white', this.COLOR_BLACK);
      //   document.documentElement.style.setProperty('--black', this.COLOR_WHITE);
      // } else {
        document.documentElement.style.setProperty('--black', this.COLOR_BLACK);
        document.documentElement.style.setProperty('--white', this.COLOR_WHITE);
      // }

      const currentVariable = `${primaryVariable}${value}`;

      const hslColor = this.toHSL(baseColor, index);
      const hexadecimalColor = this.hslToHex(hslColor);

      const hslGray = this.toHSL(baseColor, index, true);
      const hexadecimalGray = this.hslToHex(hslGray);

      document.documentElement.style.setProperty(
        `${primaryVariable}${value}`,
        hexadecimalColor
      );
      document.documentElement.style.setProperty(`--gray-${value}`, hexadecimalGray);

      if (value === 40) {
        document.documentElement.style.setProperty('--text-color', hexadecimalGray);
      }
    }
  }

  // chatGPT
  public hexToHsb(hex) {
    // Parse the hexadecimal string into individual RGB values
    var r = parseInt(hex.substring(1, 3), 16);
    var g = parseInt(hex.substring(3, 5), 16);
    var b = parseInt(hex.substring(5, 7), 16);

    // Convert the RGB values to HSB
    var hsb = { h: 0, s: 0, b: 0 };
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var delta = max - min;
    hsb.b = max;
    hsb.s = max !== 0 ? 255 * delta / max : 0;
    if (hsb.s !== 0) {
      if (r === max) {
        hsb.h = (g - b) / delta;
      } else if (g === max) {
        hsb.h = 2 + (b - r) / delta;
      } else {
        hsb.h = 4 + (r - g) / delta;
      }
    } else {
      hsb.h = -1;
    }
    hsb.h *= 60;
    if (hsb.h < 0) {
      hsb.h += 360;
    }
    hsb.s *= 100 / 255;
    hsb.b *= 100 / 255;
    return hsb;
  }


  private toHSL(hex: string, variation?: number, isGray = false): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h = (max + min) / 2;
    let s = (max + min) / 2;
    let l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;

      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    if (variation) {
      const adaptedValues = this.variationAdapter(h, s, l, variation, isGray);
      h = adaptedValues.h;
      s = adaptedValues.s;
      l = adaptedValues.l;
    }

    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  private variationAdapter(
    h: number,
    s: number,
    l: number,
    variation: any,
    isGray = false
  ): ColorHSL {
    let newDefinitions: ColorHSL;

    switch (variation) {
      case 10:
        newDefinitions = {
          h,
          s: s + ((100 - s) * 3) / 4,
          l: (l * 1) / 4,
        };
        break;

      case 20:
        newDefinitions = {
          h,
          s: s + ((100 - s) * 2) / 4,
          l: (l * 2) / 4,
        };
        break;

      case 30:
        newDefinitions = {
          h,
          s: s + ((100 - s) * 1) / 4,
          l: (l * 3) / 4,
        };
        break;

      case 40:
        newDefinitions = {
          h,
          s,
          l,
        };
        break;

      case 50:
        newDefinitions = {
          h,
          s: (s * 5) / 6,
          l: l + ((100 - l) * 1) / 6,
        };
        break;

      case 60:
        newDefinitions = {
          h,
          s: (s * 4) / 6,
          l: l + ((100 - l) * 2) / 6,
        };
        break;

      case 70:
        newDefinitions = {
          h,
          s: (s * 3) / 6,
          l: l + ((100 - l) * 3) / 6,
        };
        break;

      case 80:
        newDefinitions = {
          h,
          s: (s * 2) / 6,
          l: l + ((100 - l) * 4) / 6,
        };
        break;

      case 90:
        newDefinitions = {
          h,
          s: (s * 1) / 6,
          l: l + ((100 - l) * 5) / 6,
        };
        break;
    }

    // if (this.themeService.getSelectedTheme() === 'dark') {
    //   newDefinitions.s = newDefinitions.s * 0.68;
    // }

    if (isGray) {
      newDefinitions.l = newDefinitions.l * 1.1;
      newDefinitions.s = newDefinitions.s * 0.08;
    }

    return newDefinitions;
  }

  private hslToHex(color: any): string {
    const params = color
      .substring(color.indexOf('(') + 1, color.indexOf(')'))
      .replace('%', '')
      .replace('%', '')
      .split(',')
      .map((item: string) => item.trim());

    const h = params[0] / 360;
    const s = params[1] / 100;
    const l = params[2] / 100;

    let r: number;
    let g: number;
    let b: number;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      // tslint:disable-next-line: no-shadowed-variable
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x: number) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }
}

export interface ColorHSL {
  h: number;
  s: number;
  l: number;
}
