<div class="container form-group">
  <div class="switch__container d-flex">
    <input
      [id]="'switch-shadow' + rdmId"
      class="switch switch--shadow"
      type="checkbox"
      [(ngModel)]="inputValue"
      [disabled]="internalDisabled"
    />
    <label [for]="'switch-shadow' + rdmId"></label>
  </div>
  <div class="label">{{ label }}</div>
</div>
