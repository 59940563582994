import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BaseRenderComponent } from './base-render/base-render.component';
import { CommonModule } from '@angular/common';
import { ContentAlbumComponent } from './content-album/content-album.component';
import { ContentClipComponent } from './content-clip/content-clip.component';
import { ContentTextComponent } from './content-text/content-text.component';
import { ContentVideoComponent } from './content-video/content-video.component';
import { RenderBgoComponent } from './render-bgo/render-bgo.component';
import { RenderLayer1Component } from './render-layer1/render-layer1.component';
import { RenderLayer2Component } from './render-layer2/render-layer2.component';
import { RenderOverlayComponent } from './render-overlay/render-overlay.component';

@NgModule({
  declarations: [

    BaseRenderComponent,
    ContentAlbumComponent,
    ContentClipComponent,
    ContentTextComponent,
    ContentVideoComponent,
    RenderBgoComponent,
    RenderLayer1Component,
    RenderLayer2Component,
    RenderOverlayComponent,

  ],
  exports: [

    BaseRenderComponent,
    ContentAlbumComponent,
    ContentClipComponent,
    ContentTextComponent,
    ContentVideoComponent,

  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    // NO_ERRORS_SCHEMA
  ],
})
export class PrototypesComponentsModule { }
