<div class="main-control" style="position: fixed; left: 0px; bottom: 0px; z-index: 1000;">
  <div class="row pt-5" *ngIf="showLogin">
    <div class="col-8" style="background-color: #666; width: 400px">
      <ox-form
        [configuracao]="formLogin"
        [tamanhoColuna]="'col-12'"
        (gravar)="login($event)"
        gravarLabel="LOGIN"
      ></ox-form>
      <hr />
      <div class="col-4">
        <div class="row" *ngIf="authService.userData as user">
          <div class="col-md-12">
            <div class="media">
              <div class="media-body">
                <h2>
                  Hello:
                  <strong>{{
                    user.displayName ? user.displayName : "User"
                  }}</strong>
                </h2>
                <p>
                  User ID: <strong>{{ user.uid }}</strong>
                </p>
                <p>
                  Email: <strong>{{ user.email }}</strong>
                </p>
                <p>
                  Email Verified: <strong>{{ user.emailVerified }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ox-button
        class="mt-5"
        label="FORGOT"
        (onClick)="forgotPassword()"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="SEND EMAIL"
        (onClick)="authService.SendVerificationMail()"
      ></ox-button>

      <ox-button class="mt-5" label="EXIT" (onClick)="signOut()"></ox-button>

      <ox-button
        class="ml-3"
        label="HIDE"
        (onClick)="toggleLogin()"
      ></ox-button>
    </div>
  </div>

  <ox-button
    label="SHOW"
    (onClick)="toggleLogin()"
    *ngIf="!showLogin"
  ></ox-button>
</div>

<app-base-cms [hidden]="!showLogin"></app-base-cms>
