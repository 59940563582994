import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ZenAlbumRaw, ZenItem, ZenKaoxApiService, ZenProject } from 'src/app/services/zen-kaox-api.service';

@Component({
  selector: 'app-show-item',
  templateUrl: './show-item.component.html',
  styleUrls: ['./show-item.component.scss']
})
export class ShowItemComponent implements OnInit {

  loaded: boolean;

  @Output() fechar = new EventEmitter();
  @Input() item : any;

  @Input() url = "http://kaox.tv/albums/";
  @Input() folder : string;

  fullImage : any;

  constructor(public api:ZenKaoxApiService, private sanitizer: DomSanitizer) { }


  ngOnInit() {

    console.log("SHOW ITENS");

  }

  ngOnChanges(changes: SimpleChanges) {

    console.log(changes);

    if(this.item) {

      let folder = this.url + this.folder + '/';
      let extension = '_250.jpg';

      this.fullImage = this.sanitizer.bypassSecurityTrustUrl(folder + this.item.filename ); // + extension);
      this.loaded = true;
    }

    // if(this.rows.length > 0) {
    //   this.loadItens();
    // }

  }

  fechando(): void {

    this.loaded = false;
    this.fechar.emit();

  }

}
