import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormService, FormTemplate } from "src/app/services/form.service";

import { Clipboard } from '@angular/cdk/clipboard';
import { FirebaseApiService } from "src/app/services/firebase.service";
import { LogoComponent } from "src/app/components/logo/logo.component";
import { ZenKaoxApiService } from "../../services/zen-kaox-api.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-collection-cms',
  templateUrl: './collection-cms.component.html',
  styleUrls: ['./collection-cms.component.scss']
})
export class CollectionCmsComponent implements OnInit {

  @Input() item = null;
  @Input() base = null;
  @Output() openCollection = new EventEmitter();

  // @Output() gravar = new EventEmitter();
  pronto: boolean;
  editar: boolean = false;
  lista: boolean = false;

  configBase: FormTemplate[];

  configFormMaster: FormTemplate[];


  dadosBASE: any;
  dadosITEM: any;
  listaInputs: any[];
  confirmaDelete: boolean;



  constructor(private formService: FormService, public firebase: FirebaseApiService,
    private clipboard: Clipboard,
    public firestore: AngularFirestore, private fb: FormBuilder) {


  }

  ngOnInit(): void {

    this.formService.getCollectionForm().subscribe((res) => {

      // console.log('INIT COLLECTION', res);

      this.configBase = res;
      this.pronto = true;

    });

    this.readForm(this.item);

  }

  toggleSetup(item: any): void {

    this.editar = !this.editar;
    this.logPath();

  }

  toggleList(): void {

    this.lista = !this.lista;

  }



  /// CRUD

  //CRUD FORM
  readForm(item: any): void {

    if (item) {
      this.firebase.getItemData(item.path).subscribe((res: any) => {
        // console.log('formBase', res);
        this.dadosBASE = res;
        this.base = res.path + '/' + res.colecao;

        this.firebase.getCollectionData(this.dadosBASE.modelo + "/itens").subscribe((mod) => {

          // console.log('modeloXXXX', mod);
          mod.sort((a, b) => a.ordem - b.ordem);
          this.configFormMaster = mod;
        });

        this.firebase.getCollectionData(this.base).subscribe((res) => {

          // console.log('itens', res);
          this.listaInputs = res;
        });

      });


    }

  }

  saveForm(item: any): void {

    if (this.dadosBASE?.path) {
      this.firestore.doc<any>(this.dadosBASE?.path).update(item);
    } else {
      this.createForm(item);
    }

  }

  createForm(item: any): void {
    // item[item.colecao] = [];
    this.firebase.addItemToCollection(this.base, item);
  }

  clearForm(): void {
    this.dadosITEM = null;
    this.dadosBASE = null;

  }

  cloneForm(): void {
    this.dadosBASE.path = null;
    this.dadosBASE.nome += ' clone';
    this.dadosBASE.label += ' clone';
  }

  deleteForm(): void {
    this.firestore.doc<any>(this.dadosBASE?.path).delete();
    this.confirmaDelete = false;
  }

  toggleDelete(): void {
    this.confirmaDelete = !this.confirmaDelete;
  }

  printObject(): void {

    console.log('print item: ', this.dadosBASE);

  }

  logPath(): void {
    this.clipboard.copy(this.dadosBASE?.path);
    console.log('loading collection: ', this.dadosBASE?.path);
  }



}
