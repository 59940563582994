<div class="text-bgo">
  <!-- <p>grid-menu works!</p> -->
</div>

<div id="sidebar">

	<div id="albums" *ngIf="loaded">
			<div class="album" *ngFor="let item of projects">
        <app-thumb [data]="item"></app-thumb>
			</div>
	</div>

</div>
