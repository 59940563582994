import { Component, ContentChild, Input, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';

const OX_FILE_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxFileComponent),
  multi: true,
};

@Component({
  selector: 'ox-file',
  templateUrl: './ox-file.component.html',
  styleUrls: ['./ox-file.component.scss'],
  providers: [OX_FILE_INPUT_VALUE_ACCESSOR],
})
export class OxFileComponent implements ControlValueAccessor {
  internalInputValue: any = '';
  @Input() internalDisabled = false;
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @Input() label = '';
  @Input() placeholder = '';
  @Input() id: string = null;
  @Input() maxlength = '999';
  @Input() multiline = false;

  @Input() mask;
  @Input() type;
  @Input() requerido = false;
  showDelete = false;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private storage: AngularFireStorage) {
  }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public onChange(event: any): void { }

  feedback_id(): string {
    if (this.id) {
      return 'feedback_' + this.id;
    } else {
      return null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.internalDisabled = isDisabled;
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = event.target.files[0].name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe((url) => {
          console.log('URL:', url);
          this.inputValue = url;
        });
      })
    ).subscribe();

  }

  deleteFile(): void {

    let ref = this.storage.refFromURL(this.inputValue);
    ref.delete().subscribe((res) => {
      console.log(this.inputValue, 'Foi removido com sucesso!');
      this.inputValue = null;
      this.showDelete = false;
    });
  }

  openFile(): void {

    window.open(this.inputValue, '_blank');

  }

  toggleDelete(): void {

    this.showDelete = !this.showDelete;

  }

}
