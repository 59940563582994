<div class="main px-5 pt-5" style="background-color: #666; min-height: 100px">
  <div class="row">
    <div class="col-4">
      <h1>
        <p>[FORM MAKER] v0.2</p>
      </h1>
    </div>
    <div class="col-4 mt-2">
      <h3></h3>
    </div>
    <div class="col-4 d-inline text-right">
      <!-- <ox-button
        class="m-3"
        [label]="btn.name"
        (onClick)="eventoBotao(btn.id)"
        *ngFor="let btn of buttons"
      ></ox-button> -->
      <hr class="my-3" />
    </div>
  </div>
</div>

<div class="w-100 row p-2">
  <div class="col-2">
    <div class="p-3">
      <ox-select
        id="baseRoot"
        label="modelo"
        campoChave="path"
        [(ngModel)]="modelo"
        [itens]="listaForms"
      ></ox-select>
      <ox-button class="mt-5" label="LOAD" (onClick)="loadForm()"></ox-button>
    </div>

    <div class="px-3 my-3" style="background-color: rgba(102, 102, 102, 0.618)">
      <h2>{{ dadosBASE?.path ? dadosBASE?.nome : "NOVO" }}</h2>
      <ox-form
        [configuracao]="configBase"
        [dados]="dadosBASE"
        [tamanhoColuna]="'col-12'"
        (gravar)="saveForm($event)"
        [gravarLabel]="dadosBASE?.path ? 'UPDATE' : 'CREATE'"
      ></ox-form>

      <hr />

      <ng-container *ngIf="dadosBASE">
        <ox-button
          class="mt-5"
          label="CLEAR"
          (onClick)="clearForm()"
        ></ox-button>
        <ox-button
          class="mt-5"
          label="CLONE"
          (onClick)="cloneForm()"
        ></ox-button>

        <ox-button
          class="mt-5"
          label="DELETE"
          (onClick)="toggleDelete()"
          *ngIf="!showDelete"
        ></ox-button>

        <ox-button
          class="mt-5"
          label="YES, DELETE!"
          (onClick)="deleteForm()"
          *ngIf="showDelete"
        ></ox-button>

        <ox-button
          class="mt-5"
          label="CANCEL DELETE"
          (onClick)="toggleDelete()"
          *ngIf="showDelete"
        ></ox-button>

        <ox-button
          class="mt-5"
          label="PRINT"
          (onClick)="printForm()"
          *ngIf="!showDelete"
        ></ox-button>
      </ng-container>
    </div>

    <!-- <div *ngFor="let item of listaForms" class="w-100 m-1 row">
      <div class="col-9 form-group px-4 ml-n2" style="font-size: 1.2em">
        <ox-button [label]="item.nome" (onClick)="readForm(item)"></ox-button>
      </div>
    </div> -->

    <div class="px-3 mt-5" style="background-color: rgba(52, 152, 52, 0.618)">
      <hr />

      <ox-select
        id="baseRoot"
        label="importar"
        campoChave="path"
        [(ngModel)]="importar"
        [itens]="listaForms"
      ></ox-select>

      <ox-select
        id="baseRoot"
        label="importar"
        campoChave="path"
        [(ngModel)]="importarItem"
        [itens]="listaImport"
        *ngIf="listaImport"
      ></ox-select>

      <ox-button
        class="mt-5"
        label="LOAD"
        (onClick)="importFrom()"
        *ngIf="!listaImport"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="IMPORT"
        (onClick)="importFromItem()"
        *ngIf="listaImport"
      ></ox-button>
    </div>
  </div>

  <div class="col-1">
    <ng-container *ngIf="listaInputs">
      <div *ngFor="let item of listaInputs" class="w-100 m-1 row">
        <div class="col-12 form-group" style="font-size: 1.2em">
          <ox-button
            [label]="item.label"
            (onClick)="readInput(item)"
          ></ox-button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-4 p-3" style="background-color: rgba(102, 102, 102, 0.618)">
    <h2>{{ dadosITEM?.path ? dadosITEM?.label : "NOVO" }}</h2>
    <ox-form
      [configuracao]="configFormMaster"
      [dados]="dadosITEM"
      [tamanhoColuna]="'col-6'"
      (gravar)="saveInput($event)"
      [gravarLabel]="dadosITEM?.path ? 'UPDATE' : 'CREATE'"
    ></ox-form>
    <hr />
    <ox-button
      class="mt-5"
      label="CLEAR"
      (onClick)="clearInput()"
      *ngIf="dadosITEM"
    ></ox-button>
    <ox-button
      class="mt-5"
      label="CLONE"
      (onClick)="cloneInput()"
      *ngIf="dadosITEM"
    ></ox-button>
    <ox-button
      class="mt-5"
      label="DELETE"
      (onClick)="deleteInput()"
      *ngIf="dadosITEM"
    ></ox-button>
  </div>

  <div class="col-5">
    <div
      class="w-100 ml-3 p-3"
      style="background-color: rgba(102, 102, 102, 0.618)"
    >
      <h2>FORM DEMO: {{ dadosBASE?.nome }}</h2>
      <ox-form
        [configuracao]="listaInputs"
        [dados]="teste"
        [tamanhoColuna]="'col-12'"
        (gravar)="exportarForm()"
        gravarLabel="EXPORT"
        *ngIf="listaInputs"
      ></ox-form>
    </div>
  </div>
</div>
