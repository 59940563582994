<app-logo type="sub"></app-logo>
<div class="grid pt-3">
  <div class="color">
    <h1 class="text-right m-0">{{ album?.title }}</h1>
  </div>

  <div id="info w-100">
    <div class="pull-right text-right" style="width: 640px">
      <div id="text-block">
        {{ album?.desc }}
      </div>
      {{ album?.title }}
    </div>
  </div>

  <app-grid-submenu
    [metadata]="album"
    [rows]="rows"
    (open)="abrir($event)"
  ></app-grid-submenu>
</div>
<app-show-item
  [item]="atual"
  [folder]="album.folder"
  *ngIf="album"
></app-show-item>

<div class="text-bgo" *ngIf="album?.title">
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album.id }}</p>
  <p>project works! {{ album.hitcounter }}</p>
  <p>project works! {{ album.folder }}</p>
  <p>project works! {{ album.dynamic }}</p>
  <p>project works! {{ album.desc }}</p>
  <p>project works! {{ album.date }}</p>
  <p>project works! {{ album.custom_data }}</p>
  <p>project works! {{ album.commentson }}</p>
  <p>project works! {{ album.closecomments }}</p>
  <p>project works! {{ album.album_theme }}</p>
  <p>project works! {{ album.album_sortdirection }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
  <p>project works! {{ album?.title }}</p>
</div>
