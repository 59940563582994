<div class="form-group mb-3">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>

  <textarea matInput
  [id]="label"
    [name]="label"
    [(ngModel)]="inputValue"
    [disabled]="internalDisabled"
    role="textbox" rows="10">
  </textarea>
</div>
