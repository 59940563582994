import { Component, ContentChild, Input, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const OX_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxInputColorComponent),
  multi: true,
};

@Component({
  selector: 'ox-color',
  templateUrl: './ox-color.component.html',
  styleUrls: ['./ox-color.component.scss'],
  providers: [OX_INPUT_VALUE_ACCESSOR],
})
export class OxInputColorComponent implements ControlValueAccessor {
  internalInputValue: any = '';
  @Input() internalDisabled = false;
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @Input() label = '';
  @Input() placeholder = '';
  @Input() id: string = null;
  @Input() maxlength = '999';
  @Input() multiline = false;

  @Input() mask;
  @Input() type;
  @Input() requerido = false;
  viewPassword = false;

  constructor() { }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public onChange(event: any): void { }

  feedback_id(): string {
    if (this.id) {
      return 'feedback_' + this.id;
    } else {
      return null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.internalDisabled = isDisabled;
  }

  mostrarPassword(): void {
    this.viewPassword = !this.viewPassword;
  }

}
