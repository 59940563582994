<div class="form-group mb-3">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>

  <input
    type="number"
    [id]="label"
    [name]="label"
    [(ngModel)]="inputValue"
    [disabled]="internalDisabled"
  />
</div>
