<div class="form-group mb-3">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>

  <div class="row" *ngIf="!showEdit && !inputValue">
    <div class="col" *ngIf="dadosBASE?.path">
      <ox-button label="VIEW" (onClick)="openCollection()"></ox-button>
    </div>
    <div class="col" *ngIf="path">
      <ox-button
        [label]="dadosBASE?.path ? 'EDIT' : 'SETUP'"
        (onClick)="setupCollection()"
      ></ox-button>
    </div>
  </div>

  <div class="mt-2 p-2" *ngIf="loaded && showEdit">
    <ox-form
      [configuracao]="configBase"
      [dados]="dadosBASE"
      [tamanhoColuna]="'col-12'"
      (gravar)="saveCollection($event)"
      [gravarLabel]="dadosBASE?.path ? 'UPDATE' : 'CREATE'"
    ></ox-form>
  </div>

  <div *ngIf="inputValue">
    <div class="row" *ngIf="!showDelete">
      <div class="col">
        <ox-button label="OPEN" (onClick)="openCollection()"></ox-button>
      </div>
      <div class="col">
        <ox-button label="REMOVE" (onClick)="toggleDelete()"></ox-button>
      </div>
    </div>
    <div class="row" *ngIf="showDelete">
      <div class="col">
        <ox-button label="DELETE!" (onClick)="deleteCollection()"></ox-button>
      </div>
      <div class="col">
        <ox-button label="CANCEL" (onClick)="toggleDelete()"></ox-button>
      </div>
    </div>
  </div>
</div>
