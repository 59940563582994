<div [style.color]="content.color" [ngClass]="content.style">
  <h1>
    {{content.title}}
  </h1>


<button (click)="triggerNext()">Go to destination</button>

<div id="custom-container">
  <div id="destination">
    You've reached your destination.
  </div>
</div>


</div>
