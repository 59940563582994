import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface firebaseProject {

  query: string;
  total: number;
  version: string;
  rows: [{
    custom_data: string;
    date: string;
    desc: string;
    folder: string;
    id: string;
    sort_type: string;
    thumb: string;
    title: string;
    tags: [
      any
    ]
  }]
}

export interface Album { id: string; custom_data: string; path: string; desc: string; title: string; folder: string; thumb: string; date: string; tags: { name: string; id: string; }[]; sort_type: string; };


@Injectable({
  providedIn: 'root'
})
export class FirebaseApiService {

  private itemsCollection: AngularFirestoreCollection<any>;


  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {


  }


  getC(path: string, collection: string): Observable<any[]> {

    let data = this.firestore.collection<any>(path + '/' + collection);
    return data.valueChanges();

  }

  getI(path: string): Observable<any[]> {

    let doc = this.firestore.doc<any>(path);
    return doc.valueChanges();

  }

  getCollectionData(path: string): Observable<any[]> {

    let collection = this.firestore.collection<any>(path);

    return collection.valueChanges();

  }

  newCollection(path: string): AngularFirestoreCollection<any> {

    // this.firestore.doc<any>(path).collection('photos').add(doc).then((res) => {
    //   console.log(res.id, res.path);
    //   this.firestore.doc<any>(res.path).update({ path: res.path });
    // });

    let collection = this.firestore.collection<any>(path);
    return collection;
    // console.log(collection);

    // return collection.valueChanges();

  }

  getItemData(path: string): Observable<any[]> {

    let doc = this.firestore.doc<any>(path);

    return doc.valueChanges();

  }

  addItemToCollection(path: string, item: any): Promise<any> {

    let base = this.firestore.collection<any>(path);

    return base.add(item).then((res: any) => {
      console.log(res);
      // base.update({ path: res.path });
      this.firestore.doc<any>(res.path).update({ path: res.path });
      return res.path;
    });

  }


  updateItem(itemPath: string, item: any): void {
    this.firestore.doc<any>(itemPath).update(item);
  }


  removeItem(path: string): any {
    return this.firestore.doc<any>(path).delete();
  }

  private basePath = '/uploads';

  sortCollection(collection: any, parameter: string = 'sort_order'): any {

    return collection.sort((a, b) => parseInt(a[parameter], 10) - parseInt(b[parameter], 10));

  }


  filterVisibility(collection: any, parameter: string = 'visivel'): any {

    return collection.filter((item) => { return item[parameter]});

  }

  // pushFileToStorage(fileUpload: FileUpload): Observable<number> {
  //   const filePath = `${this.basePath}/${fileUpload.file.name}`;
  //   const storageRef = this.storage.ref(filePath);
  //   const uploadTask = this.storage.upload(filePath, fileUpload.file);

  //   uploadTask.snapshotChanges().pipe(
  //     finalize(() => {
  //       storageRef.getDownloadURL().subscribe(downloadURL => {
  //         fileUpload.url = downloadURL;
  //         fileUpload.name = fileUpload.file.name;
  //         this.saveFileData(fileUpload);
  //       });
  //     })
  //   ).subscribe();

  //   return uploadTask.percentageChanges();
  // }

}
