<div id="logo" *ngIf="type==='sub'" routerLink="/">
  <img src="assets/images/logotipo.png" alt="portfolio / Kaue Costa" />
  <map name="Map" id="Map">
    <area shape="rect" coords="311,310,377,330" href="mailto:send@kaox.tv" alt="contact" />
  </map>
</div>

<div id="logo" *ngIf="type==='default'">
  <img src="assets/images/home.png" alt="portfolio / Kaue Costa" width="551" height="391" usemap="#Map" />
  <map name="Map" id="Map">
    <area shape="rect" coords="311,310,377,330" href="mailto:send@kaox.tv" alt="contact" />
  </map>
</div>
