import {
  Component,
  OnInit
} from '@angular/core';
import {
  BetterCallDevService
} from 'src/app/services/better-call-dev.service';
import {
  TzKTApiService
} from 'src/app/services/tz-kt.service';
import {
  ZenKaoxApiService
} from '../../services/zen-kaox-api.service'
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  contract = 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton'; //HIC ET NUNC CONTRACT
  account = 'tz1XFHXtE9CXskkZmkwqytzZaKoQApvNxPhp';
  tokenId = '45820';


  // https://better-call.dev/docs

  constructor(public api: ZenKaoxApiService, public tez: TzKTApiService, public bcd: BetterCallDevService) {}

  ngOnInit() {

    console.log("TESTE");

    this.bcd.getNetworks().subscribe((res) => {

      console.log("NETWORKS:");
      console.log(res);

    }, (err) => { console.log(err); });

    // this.bcd.setNetwork('edo2net');
    this.bcd.setNetwork('mainnet');

    this.bcd.searchTokens(this.contract, this.account).subscribe((res) => {

      console.log("TOKENS:");
      console.log(res);

    }, (err) => { console.log(err); });


    this.bcd.getAccountInfo(this.account).subscribe((res) => {

      console.log("getAccountInfo:");
      console.log(res);

    }, (err) => { console.log(err); });



    this.bcd.getAccountTokenBalance(this.account, this.contract).subscribe((res) => {

      console.log("getAccountTokenBalance:");
      console.log(res);

    }, (err) => { console.log(err); });



  }


  testTZTK(): void {


    this.tez.getContract(this.contract).subscribe((res) => {

      console.log("CONTRACT:");
      console.log(res);

    }, (err) => { console.log(err); });

    this.tez.getContractStorage(this.contract).subscribe((res) => {

      console.log("CONTRACT STORAGE:");
      console.log(res);

    }, (err) => { console.log(err); });


    this.tez.getAccount(this.account).subscribe((res) => {

      console.log("ACCOUNT");

      console.log(res);

    }, (err) => { console.log(err); });

    this.tez.getAccountContract(this.account).subscribe((res) => {

      console.log("ACCOUNT CONTRACT:");

      console.log(res);

    }, (err) => { console.log(err); });

    this.tez.getAccountMetadata(this.account).subscribe((res) => {

      console.log("ACCOUNT METADATA:");

      console.log(res);

    }, (err) => { console.log(err); });

    this.tez.getAccountReport(this.account).subscribe((res) => {

      console.log("ACCOUNT REPORT:");

      console.log(res);

    }, (err) => { console.log(err); });

    this.tez.getAccountBalanceHistory(this.account).subscribe((res) => {

      console.log("ACCOUNT BALANCE:");

      console.log(res);

    }, (err) => { console.log(err); });


    this.tez.getAccountOperations(this.account).subscribe((res) => {

      console.log("ACCOUNT OPERATIONS:");

      console.log(res);

    }, (err) => { console.log(err); });

  }

  testZenAPI(): void {


    this.api.getAllProjects().subscribe((res) => {

      console.log(res);

    }, (err) => { console.log(err); });

    this.api.getAllTags().subscribe((res) => {

      console.log("TAGSS:  ", res);

    }, (err) => { console.log(err); });


    this.api.getAlbum(37).subscribe((res) => {

      console.log("CNTSSS:  ", res);

    }, (err) => { console.log(err); });


  }

}
