import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { AlbumComponent } from './pages/album/album.component';
import { AppComponent } from './app.component'
import { AuthGuard } from './guard/auth.guard';
import { CMSAdminComponent } from './pages/cms/cms.component';
import { FirebaseComponent } from './pages/firebase/firebase.component';
import { FormComponent } from './pages/form/form.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ProtoComponent } from './pages/proto/proto.component';
import { RootComponent } from './pages/root/root.component';
import { TestComponent } from './pages/test/test.component';
import { TokensComponent } from './pages/tokens/tokens.component';

const routes: Routes = [
  {
    path: '', component: RootComponent,
    children: [

      //-----------------------------------------------------------
      // HOME
      //-----------------------------------------------------------
      {
        path: '',
        component: HomeComponent,
      },

      //-----------------------------------------------------------
      // PROTO
      //-----------------------------------------------------------
      {
        path: 'proto',
        component: ProtoComponent,
      },


      //-----------------------------------------------------------
      // LOGIN
      //-----------------------------------------------------------
      {
        path: 'login',
        component: LoginComponent,
      },



      //-----------------------------------------------------------
      // ABOUT
      //-----------------------------------------------------------
      {
        path: 'about',
        component: AboutComponent,
      },


      //-----------------------------------------------------------
      // TOKENS
      //-----------------------------------------------------------
      {
        path: 'tkns',
        component: TokensComponent,
      },

      //-----------------------------------------------------------
      // PORTFOLIO / ALBUM
      //-----------------------------------------------------------

      {
        path: 'project',
        component: PortfolioComponent,
      },

      {
        path: 'project/:id',
        component: AlbumComponent,
      },

      //-----------------------------------------------------------
      // GALLERY
      //-----------------------------------------------------------
      {
        path: 'gallery',
        component: GalleryComponent,
      },

      //-----------------------------------------------------------
      // COLLECTION CAT
      //-----------------------------------------------------------
      {
        path: 'gallery/:cat',
        component: GalleryComponent,
      },

      //-----------------------------------------------------------
      // COLLECTION TAG
      //-----------------------------------------------------------
      {
        path: 'gallery/project/:tag',
        component: GalleryComponent,
      },



      //-----------------------------------------------------------
      // DEBUG TEST
      //-----------------------------------------------------------
      {
        path: 'test',
        component: TestComponent,
        canActivate: [AuthGuard]
      },



      //-----------------------------------------------------------
      // FIREBASE TEST
      //-----------------------------------------------------------
      {
        path: 'firebase',
        component: FirebaseComponent,
        canActivate: [AuthGuard]
      },


      //-----------------------------------------------------------
      // FORM MAKER TEST
      //-----------------------------------------------------------
      {
        path: 'form',
        component: FormComponent,
        canActivate: [AuthGuard]
      },

      //-----------------------------------------------------------
      // CMS ADMIN
      //-----------------------------------------------------------
      {
        path: 'admin',
        component: CMSAdminComponent,
        canActivate: [AuthGuard]
      },







    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


