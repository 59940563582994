import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirebaseApiService } from 'src/app/services/firebase.service';
import { ZenKaoxApiService } from '../../services/zen-kaox-api.service';
import { finalize } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';

export interface Album { id: string; custom_data: string; path: string; desc: string; title: string; folder: string; thumb: string; date: string; tags: { name: string; id: string; }[]; sort_type: string; visivel: boolean }
export interface Photo {
  credit: string; desc: string; albumid: string; custom_data: string; path: string; width: string; height: string; filename: string; title: string; folder: any; id: string; date: string; visivel: boolean
}

export interface ConfigGeral {
  nome: string;
  label: string;
  obrigatorio: boolean;
  tipo: string;
  campoNormalizado?: string;
  coluna?: boolean;
  form: boolean;
  endpoint?: string;
  rota?: string;
  campoTexto?: string;
  formato?: string;
  valor?: any;
}



@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent implements OnInit {

  private itemsCollection: AngularFirestoreCollection<any>;

  private albumsCollection: AngularFirestoreCollection<any>;
  private photosCollection: AngularFirestoreCollection<any>;

  albumsFirebase: Observable<any[]>;
  albums: Album[];
  public album: Album;
  formAlbum: FormGroup;

  public photos: Photo[];
  photo: Photo;
  formItem: FormGroup;



  private testeCollection: AngularFirestoreCollection<any>;
  items$: Observable<any[]>;
  sizeFilter$: BehaviorSubject<string | null>;
  colorFilter$: BehaviorSubject<string | null>;

  private itemDoc: AngularFirestoreDocument<any>;
  items: Observable<any[]>;
  item: Observable<any>;

  form: FormGroup;
  buttons = [
    {
      name: 'importar albums',
      id: 'import',
      method: this.importAlbums
    },
    {
      name: 'btn 2',
      id: 'btn',
      method: this.bang
    },
    {
      name: 'btn 3',
      id: 'btn',
      method: this.bang
    },
  ];



  itensSelect = [
    {
      value: 1,
      text: 'opcao1'
    },
    {
      value: 2,
      text: 'opcao2'
    },
    {
      value: 3,
      text: 'opcao3'
    },
  ];

  itensCheckbox = [
    "opcao 111",
    "opcao 22",
    "opcao 3",
    "opcao 04",
  ];
  dadosAlbum: ConfigGeral[];
  dadosPhoto: ConfigGeral[];


  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;


  constructor(
    public firebase: FirebaseApiService,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    public api: ZenKaoxApiService
  ) {

    // this.items = firestore.collection('tags').valueChanges();
    //opcao

    this.albumsCollection = firestore.collection<any>('PROJECTS');
    console.log(this.albumsCollection);

    // this.albumsCollection.valueChanges().subscribe((res) => {

    //   this.albums = res;

    // });

    // this.testesIniciais();




  }

  ngOnInit(): void {

    this.dadosAlbum = [
      {
        nome: 'title',
        label: 'title',
        obrigatorio: true,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'custom_data',
        label: 'custom_data',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'date',
        label: 'date',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'thumb',
        label: 'thumb',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'folder',
        label: 'folder',
        obrigatorio: true,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'desc',
        label: 'Descrição',
        obrigatorio: false,
        tipo: 'TEXTAREA',
        form: true,
      },

      {
        nome: "visivel",
        label: "Visível?",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },
    ];

    this.dadosPhoto = [
      {
        nome: 'title',
        label: 'title',
        obrigatorio: true,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'custom_data',
        label: 'custom_data',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'date',
        label: 'date',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'filename',
        label: 'filename',
        obrigatorio: false,
        tipo: 'INPUT',
        form: true,
      },

      {
        nome: 'folder',
        label: 'folder',
        obrigatorio: true,
        tipo: 'INPUT',
        form: true,
      },
      {
        nome: 'desc',
        label: 'Descrição',
        obrigatorio: false,
        tipo: 'TEXTAREA',
        form: true,
      },

      {
        nome: "visivel",
        label: "Visível?",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },
    ];


    this.iniciaForm();
  }

  private iniciaForm(): void {

    this.formAlbum = this.fb.group({

      title: ['', []],
      thumb: ['', []],
      folder: ['', []],
      date: ['', []],
      custom_data: ['', []],
      desc: ['', []],
      tags: ['', []],
      visivel: [true, []]

    });


    this.formItem = this.fb.group({

      id: ['', []],
      title: ['', []],

      folder: ['', []],
      filename: ['', []],
      desc: ['', []],
      albumid: ['', []],
      date: ['', []],
      credit: ['', []],
      custom_data: ['', []],
      width: ['', []],
      height: ['', []],
      visivel: [true, []]


    });


    this.form = this.fb.group({


      teste: ['teste 01', []],
      toggle: [true, []],
      area: ['textareaaaaaaaa', []],
      select: [1, []],
      radio: ['s', []],
      file: [null, []],
      editor: [null, []],
      checkbox: ['s', []],

    });

    this.form.get('radio').valueChanges.subscribe((res) => {
      console.log(res);
    });

    this.form.get('checkbox').valueChanges.subscribe((res) => {
      console.log(res);
    });



  }


  gravandoAlbum(item: Album): void {

    console.log(item);
    this.album = item;
    this.firestore.doc<any>(this.album?.path).update(this.album);


  }

  gravandoPhoto(item: Photo): void {

    console.log(item);
    this.photo = item;
    this.firestore.doc<any>(this.photo?.path).update(this.photo);

  }

  eventoBotao(event: any): void {


    if (event === 'import') {
      this.importAlbums();
    } else {
      console.log(event);
    }


  }


  importAlbumsOld(): void {
    //para importar base zen -> firebase
    this.api.getAllProjects().subscribe((res: any) => {
      this.albums = res.rows;

      this.albums.forEach((album) => {
        console.log(album);
        this.albumsCollection.add(album).then((doc) => {
          console.log(doc.path);
          this.albumsCollection.doc(doc.id).update({
            name: album.folder,
            path: doc.path,
            colecao: "photos",
            type: "PROJECT",
            modelo: "forms/HFmLhnttJViNqsdSWlmO"
          });
        });
      });
    });
  }

  importAlbums(): void {
    //para importar base zen -> firebase
    this.api.getAllProjects().subscribe((res: any) => {
      this.albums = res.rows;

      console.log('ALBUMS', this.albums);


      this.albumsCollection.add({
        name: 'ZENPHOTOS4',
        colecao: "albums",
        type: "PROJECT",
        modelo: "forms/HFmLhnttJViNqsdSWlmO"
      }).then((base) => {

        console.log('base', base);
        console.log(base.path);

        this.albumsCollection.doc(base.id).update({
          path: base.path
        });

        this.albums.forEach((album) => {

          // this.firebase.addItemToCollection(base.path + '/albums', album);

          console.log(album);

          let updateObject = {
            ...album,
            ...{
              name: album.folder,
              visivel: true,
            }
          };

          this.firestore.doc<any>(base.path).collection('albums').add(updateObject).then((item) => {

            console.log(item.id, item.path);
            this.firestore.doc<any>(item.path).update({ path: item.path });


            // this.firestore.collection<any>(item.path).add()

            this.firebase.addItemToCollection(item.path + '/photos', {
              name: updateObject.name,
              colecao: "photos",
              type: "IMAGE",
              modelo: "forms/VrEyJPGnQp9ct44xJ7s2"
            }).then((photosPath) => {

              console.log('PHOTOSSS', photosPath);


              // this.firestore.doc<any>(photosPath).update({
              //   path: photosPath
              // });

              this.firestore.doc<any>(item.path).update({ album: photosPath });


              // this.albumsCollection.doc(photos.id).update({
              //   path: photosPath
              // });

              this.api.getAlbum(album.id).subscribe((res: any) => {

                console.log("ALBUM ZEN:  ", res);
                let zenPhotos = res.rows;

                zenPhotos.forEach((photo) => {
                  console.log(photo);
                  // if (album?.path) {

                  photo.folder = album.folder;
                  photo.credit = 'kaox.tv';

                  let updateObject2 = {
                    ...photo,
                    ...{
                      name: photo.filename,
                      filepath: album.folder + '/' + photo.filename,
                      visivel: true,
                    }
                  };


                  this.firestore.doc<any>(photosPath).collection('photos').add(updateObject2).then((foto) => {

                    console.log('ADICIONANDO FOTOS', foto.id, foto.path);
                    this.firestore.doc<any>(foto.path).update({ path: foto.path });
                  });
                  // }

                });//fim forEach zenPhotos
              });//getAlbum subscribe
            });//photos
          });//albums

        });
      });

    });
  }

  importAllAlbums(): void {




  }

  openAlbum(item: Album): void {

    this.album = null;
    console.log("OPEN ALBUM:", item);
    this.photo = null;

    this.formAlbum.get("title").setValue(item.title);
    this.formAlbum.get("date").setValue(item.date);
    this.formAlbum.get("custom_data").setValue(item.custom_data);
    this.formAlbum.get("folder").setValue(item.folder);
    this.formAlbum.get("thumb").setValue(item.thumb);
    this.formAlbum.get("desc").setValue(item.desc);
    this.formAlbum.get("visivel").setValue(item.visivel === null ? true : item.visivel);

    this.album = item;


    this.photosCollection = this.firestore.collection<any>(item?.path + '/photos');


    this.photosCollection.valueChanges().subscribe((res: any[]) => {
      console.log('photos THEN:', res);
      this.photos = res;

      if (res.length < 1) {
        this.importPhotos();
      }
    });

  }

  updateAlbum(): void {

    this.album.title = this.formAlbum.get("title").value;
    this.album.date = this.formAlbum.get("date").value;
    this.album.custom_data = this.formAlbum.get("custom_data").value;
    this.album.folder = this.formAlbum.get("folder").value;
    this.album.thumb = this.formAlbum.get("thumb").value;
    this.album.desc = this.formAlbum.get("desc").value;
    this.album.visivel = this.formAlbum.get("visivel").value;


    this.firestore.doc<any>(this.album?.path).update(this.album);
  }

  importPhotos(): void {

    this.api.getAlbum(this.album.id).subscribe((res: any) => {

      console.log("CNTSSS:  ", res);

      let zenPhotos = res.rows;

      zenPhotos.forEach((photo) => {
        console.log(photo);
        this.firestore.doc<any>(this.album?.path).collection('photos').add(photo).then((doc) => {
          console.log(doc.id, doc.path);
          this.firestore.doc<any>(doc.path).update({ path: doc.path });
        });
      });

    });

  }

  openPhoto(item: any): void {

    console.log("OPEN PHOTO:", item);
    this.photo = item;

    this.formItem.get("title").setValue(item.title);
    this.formItem.get("filename").setValue(item.filename);
    this.formItem.get("custom_data").setValue(item.custom_data);
    this.formItem.get("folder").setValue(item.folder);
    this.formItem.get("desc").setValue(item.desc);
    this.formItem.get("credit").setValue(item.desc);
    this.formItem.get("visivel").setValue(item.visivel === null ? true : item.visivel);


    // id: ['', []],
    // albumid: ['', []],


    // date: ['', []],
    // credit: ['', []],
    // custom_data: ['', []],
    // width: ['', []],
    // height: ['', []],

  }

  updatePhoto(): void {

    this.photo.title = this.formItem.get("title").value;
    this.photo.date = this.formItem.get("date").value;
    this.photo.custom_data = this.formItem.get("custom_data").value;
    this.photo.folder = this.formItem.get("folder").value;
    this.photo.desc = this.formItem.get("desc").value;
    this.photo.credit = this.formItem.get("credit").value;
    this.photo.visivel = this.formItem.get("visivel").value;


    this.firestore.doc<any>(this.photo?.path).update(this.photo);
  }

  deletePhoto(): void {
    this.firestore.doc<any>(this.photo?.path).delete();
  }

  addItem(item: any) {
    this.itemsCollection.add(item);
  }

  createItensTeste(): void {

    let item = {
      size: "mid",
      text: "mid thing",
      color: "red"
    };

    this.testeCollection.add(item);

  }

  bang(): void {
    console.log("Bang!");

  }

  update(item: any) {

    console.log(item);
    item.nome = item.nome + '_1'
    this.itemDoc.update(item);
  }

  testar(item: any): void {
    console.log(item);
    console.log(this.item);
  }


  testesIniciais(): void {
    this.itemsCollection = this.firestore.collection<any>('tags');
    this.testeCollection = this.firestore.collection<any>('teste');
    this.items = this.itemsCollection.valueChanges();


    this.itemDoc = this.firestore.doc<any>('tags/item');
    this.item = this.itemDoc.valueChanges();

    console.log(this.items);

    this.createItensTeste();

    this.sizeFilter$ = new BehaviorSubject(null);
    this.colorFilter$ = new BehaviorSubject(null);
    this.items$ = combineLatest(
      this.sizeFilter$,
      this.colorFilter$
    ).pipe(
      switchMap(([size, color]) =>
        this.firestore.collection('teste', ref => {
          let query: any = ref;
          if (size) { query = query.where('size', '==', size) };
          if (color) { query = query.where('color', '==', color) };
          return query;
        }).valueChanges()
      )
    );
  }


  filterBySize(size: string | null) {
    this.sizeFilter$.next(size);
  }
  filterByColor(color: string | null) {
    this.colorFilter$.next(color);
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = event.target.files[0].name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => this.downloadURL = fileRef.getDownloadURL())
    )
      .subscribe()
  }



}

