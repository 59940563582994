import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BaseCmsComponent } from './base-cms/base-cms.component';
import { CollectionCmsComponent } from './collection-cms/collection-cms.component';
import { CommonModule } from '@angular/common';
import { InterfaceComponentsModule } from '../interface/interface.module';
import { ItemCmsComponent } from './item-cms/item-cms.component';

@NgModule({
  declarations: [

    BaseCmsComponent,
    ItemCmsComponent,
    CollectionCmsComponent,

  ],
  exports: [

    BaseCmsComponent,
    ItemCmsComponent,
    CollectionCmsComponent,


  ],
  imports: [
    InterfaceComponentsModule,
    CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    // NO_ERRORS_SCHEMA
  ],
})
export class BaseComponentsModule { }
