<div class="w-100 row top-bar">
  <div class="col-10" *ngIf="item">
    <p>{{ item?.name }}</p>
  </div>
  <div class="col-10" *ngIf="!item">
    <p>_ _ _ + new collection</p>
  </div>
  <div class="col-1 text-right p-0">
    <ox-button
      [label]="editar ? 'CLOSE' : 'EDIT'"
      (onClick)="toggleSetup(item)"
    ></ox-button>
  </div>
  <div class="col-1 text-right p-0">
    <ox-button
      [label]="lista ? 'CLOSE' : 'LIST'"
      (onClick)="toggleList()"
      *ngIf="listaInputs"
    ></ox-button>
  </div>
</div>
<div
  class="w-100 row content p-3"
  style="background-color: rgba(102, 102, 102, 0.618)"
  *ngIf="editar"
>
  <div class="col-12" *ngIf="pronto">
    <h2>COLLECTION:</h2>
    <ox-form
      [configuracao]="configBase"
      [dados]="dadosBASE"
      [tamanhoColuna]="'col-12'"
      (gravar)="saveForm($event)"
      [gravarLabel]="dadosBASE?.path ? 'UPDATE' : 'CREATE'"
    ></ox-form>

    <hr />
  </div>
  <ng-container *ngIf="dadosBASE">
    <div class="col-6">
      <ox-button class="mt-5" label="CLEAR" (onClick)="clearForm()"></ox-button>

      <ox-button class="mt-5" label="CLONE" (onClick)="cloneForm()"></ox-button>
    </div>

    <div class="col-6">
      <ox-button
        class="mt-5"
        label="DELETE"
        (onClick)="toggleDelete()"
        *ngIf="!confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="YES, DELETE!"
        (onClick)="deleteForm()"
        *ngIf="confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="CANCEL DELETE"
        (onClick)="toggleDelete()"
        *ngIf="confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="PRINT"
        (onClick)="printObject()"
        *ngIf="!confirmaDelete"
      ></ox-button>
    </div>
  </ng-container>
</div>

<div class="w-100 row content p-3" *ngIf="lista">
  <div class="col-12 form-group" *ngFor="let item of listaInputs">
    <app-item-cms
      [base]="base"
      [item]="item"
      [form]="configFormMaster"
      (openCollection)="openCollection.emit($event)"
    ></app-item-cms>
  </div>
  <div class="col-12 form-group">
    <app-item-cms
      [base]="base"
      [item]="null"
      [form]="configFormMaster"
    ></app-item-cms>
  </div>
</div>
