import { Component, OnInit } from '@angular/core';
import { ZenKaoxApiService } from '../../services/zen-kaox-api.service'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {



  constructor(public api:ZenKaoxApiService) { }

  ngOnInit() {

    console.log("TESTE");


    this.api.getAllProjects().subscribe((res)=> {

      console.log(res);

    });

    this.api.getAllTags().subscribe((res)=> {

      console.log("TAGSS:  " , res);

    });


    this.api.getAlbum(37).subscribe((res)=> {

      console.log("CNTSSS:  " , res);

    });


  }

}
