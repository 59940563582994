import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ZenAlbumRaw, ZenItem, ZenKaoxApiService, ZenProject } from 'src/app/services/zen-kaox-api.service';

@Component({
  selector: 'app-grid-submenu',
  templateUrl: './grid-submenu.component.html',
  styleUrls: ['./grid-submenu.component.scss']
})
export class GridSubmenuComponent implements OnChanges {

  loaded: boolean;

  @Output() open = new EventEmitter();
  @Input() metadata : ZenAlbumRaw;
  @Input() rows : any;
  itens : ZenItem[];
  url = "http://kaox.tv/cache/";


  constructor(public api:ZenKaoxApiService, private sanitizer: DomSanitizer) { }


  ngOnInit() {

    console.log("TESTE");

    // this.projects = [];

    // this.api.getAllProjects().subscribe((res: ZenProject)=> {

    //   console.log(res);
    //   res.rows.forEach(row => {

    //     let url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.albumPath}/${row.folder}/${row.thumb}_250.jpg`);

    //     console.log(url);


    //     this.projects.push(
    //       {
    //         id: row.id,
    //         title: row.title,
    //         thumb: row.thumb,
    //         folder: row.folder,
    //         url,
    //         // bgo: string;
    //         tags: row.tags,
    //       }
    //     );

    //     console.log(row);


    //   });

    //   this.loaded = true;

    //   console.log(this.projects);


    //   // const imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${img.url}`);


    // });

    // this.api.getAllTags().subscribe((res)=> {

    //   console.log("TAGSS:  " , res);

    // });


    // this.api.getAlbum(37).subscribe((res)=> {

    //   console.log("CNTSSS:  " , res);

    // });


  }

  ngOnChanges(changes: SimpleChanges) {

    console.log(changes);

    if(this.rows?.length > 0) {
      this.loadItens();
    }

  }


  loadItens(): void {

    console.log("Load Itens OK");


    this.itens = [];
    let folder = this.url + this.metadata.folder + '/';
    let extension = '_250.jpg';

    this.rows.forEach(item => {

      item.imageSrc = this.sanitizer.bypassSecurityTrustUrl(folder + item.filename + extension);
      this.itens.push(item);

    });

    this.loaded = true;
    console.log(this.itens);


  }

  openProject(event: any): void {

    console.log("Abrindo: " + event.id , event);
    this.open.emit(event);

  }

}
