<p>login works!</p>
<p>login works!</p>

<!-- Sidebar navigation -->
<div class="container-fluid">
  <div class="row">
    <nav class="col-md-2 d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active">
              <i class="fas fa-user"></i>User Profile
            </a>
          </li>
          <!-- Calling SignOut() Api from AuthService -->
          <li class="nav-item">
            <a class="nav-link" (click)="authService.SignOut()">
              <i class="fas fa-sign-out-alt"></i>Log out
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Main content -->
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div class="inner-adjust">
        <div class="pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2">User Profile</h1>
        </div>
        <!-- Show user data when logged in -->
        <div class="row" *ngIf="authService.userData as user">
          <div class="col-md-12">
            <div class="media">
              <img
                class="align-self-start mr-5 img-thumbnail rounded-circle"
                src="{{
                  user.photoURL ? user.photoURL : '/assets/dummy-user.png'
                }}"
                alt="{{ user.displayName }}"
              />
              <div class="media-body">
                <h1>
                  Hello:
                  <strong>{{
                    user.displayName ? user.displayName : "User"
                  }}</strong>
                </h1>
                <p>
                  User ID: <strong>{{ user.uid }}</strong>
                </p>
                <p>
                  Email: <strong>{{ user.email }}</strong>
                </p>
                <p>
                  Email Verified: <strong>{{ user.emailVerified }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>Sign In</h3>
      <div class="formGroup">
        <input
          type="text"
          class="formControl"
          placeholder="Username"
          #userName
          required
        />
      </div>
      <div class="formGroup">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          #userPassword
          required
        />
      </div>
      <!-- Calling SignIn Api from AuthService -->
      <div class="formGroup">
        <input
          type="button"
          class="btn btnPrimary"
          value="Log in"
          (click)="authService.SignIn(userName.value, userPassword.value)"
        />
      </div>

      <ox-button
        class="mt-5"
        label="SEND EMAIL"
        (onClick)="authService.SendVerificationMail()"
      ></ox-button>
      <div class="formGroup">
        <span class="or"><span class="orInner">Or</span></span>
      </div>
      <!-- Calling GoogleAuth Api from AuthService -->
      <div class="formGroup">
        <button
          type="button"
          class="btn googleBtn"
          (click)="authService.GoogleAuth()"
        >
          <i class="fab fa-google-plus-g"></i>
          Log in with Google
        </button>
      </div>
      <div class="forgotPassword">
        <span routerLink="/forgot-password">Forgot Password?</span>
      </div>
    </div>
    <div class="redirectToLogin">
      <span
        >Don't have an account?<span
          class="redirect"
          routerLink="/register-user"
        >
          Sign Up</span
        ></span
      >
    </div>
  </div>
</div>

<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
<p>login works!</p>
