import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-render-bgo',
  templateUrl: './render-bgo.component.html',
  styleUrls: ['./render-bgo.component.scss']
})
export class RenderBgoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
