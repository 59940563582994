<div class="form-group mb-3">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>
  <div *ngIf="inputValue">
    <div class="row" *ngIf="!showDelete">
      <div class="col">
        <ox-button label="LINK" (onClick)="openFile()"></ox-button>
      </div>
      <div class="col">
        <ox-button label="REMOVE" (onClick)="toggleDelete()"></ox-button>
      </div>
    </div>
    <div class="row" *ngIf="showDelete">
      <div class="col">
        <ox-button label="DELETE!" (onClick)="deleteFile()"></ox-button>
      </div>
      <div class="col">
        <ox-button label="CANCEL" (onClick)="toggleDelete()"></ox-button>
      </div>
    </div>
  </div>
  <div *ngIf="!inputValue">
    <input type="file" (change)="uploadFile($event)" />
    <div>{{ uploadPercent | async }}</div>
  </div>
</div>
