import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const OX_LIST_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxListComponent),
  multi: true,
};

@Component({
  selector: "ox-list",
  templateUrl: "./ox-list.component.html",
  styleUrls: ["./ox-list.component.scss"],
  providers: [OX_LIST_VALUE_ACCESSOR],
})
export class OxListComponent implements ControlValueAccessor {
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};
  internalInputValue: any = "";

  @Input() label = "";
  @Input() placeholder = "";
  @Input() id: string = null;
  @Input() permiteVazio = false;
  @Input() requerido = false;
  @Input() campoChave = "id";
  @Input() campoTexto = "name";
  @Input() largura: string = null;

  desativado = false;
  nomeInput = '';
  valorInput = '';

  itens = [];

  feedback_id(): string {
    if (this.id) {
      return "feedback_" + this.id;
    } else {
      return null;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;

      if (this.onChangeCallback) {
        this.onChangeCallback(value);
      }
      if (this.onTouchedCallback) {
        this.onTouchedCallback();
      }
    }
  }

  editItem(item: any): void {
    this.nomeInput = item[this.campoTexto];
    this.valorInput = item[this.campoChave];
  }

  addItem(): void {

    if (!this.itens) {
      this.itens = [];
    }


    if (!this.valorInput) this.valorInput = this.nomeInput;

    this.itens.push({
      [this.campoTexto]: this.nomeInput,
      [this.campoChave]: this.valorInput
    });

    this.inputValue = JSON.stringify(this.itens);

  }

  removeItem(): void {

    this.itens = this.itens.filter((item) => {
      return item[this.campoChave] !== this.valorInput && item[this.campoTexto] !== this.nomeInput
    });

    console.log('REMOVE', this.itens);
    this.inputValue = JSON.stringify(this.itens);


  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
    if (value && typeof value === 'string') this.itens = JSON.parse(this.internalInputValue);
  }

  constructor() {


  }

  setDisabledState(isDisabled: boolean): void {
    this.desativado = isDisabled;
  }
}
