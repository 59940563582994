<div class="w-100 row top-bar">
  <div class="col-10" *ngIf="item">
    <p>{{ item?.name }}</p>
  </div>
  <div class="col-10" *ngIf="!item">
    <p>_ _ _ + new item</p>
  </div>
  <div class="col-1 text-right p-0 form-group"></div>
  <div class="col-1 text-right p-0 form-group">
    <ox-button
      [label]="editar ? 'CLOSE' : 'EDIT'"
      (onClick)="toggleSetup(item)"
    ></ox-button>
  </div>
</div>
<div
  class="w-100 row content p-3"
  style="background-color: rgba(102, 102, 102, 0.618)"
  *ngIf="editar"
>
  <div class="col-12" *ngIf="pronto">
    <h2>ITEM:</h2>
    <ox-form
      [path]="dadosBASE?.path"
      [configuracao]="form"
      [dados]="dadosBASE"
      [tamanhoColuna]="'col-12'"
      (gravar)="saveItem($event)"
      (action)="inputAction($event)"
      [gravarLabel]="dadosBASE?.path ? 'UPDATE' : 'CREATE'"
    ></ox-form>

    <hr />
  </div>
  <ng-container *ngIf="dadosBASE">
    <div class="col-6">
      <ox-button class="mt-5" label="CLEAR" (onClick)="clearItem()"></ox-button>

      <ox-button class="mt-5" label="CLONE" (onClick)="cloneItem()"></ox-button>
    </div>

    <div class="col-6">
      <ox-button
        class="mt-5"
        label="DELETE"
        (onClick)="toggleDelete()"
        *ngIf="!confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="YES, DELETE!"
        (onClick)="deleteItem()"
        *ngIf="confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="CANCEL DELETE"
        (onClick)="toggleDelete()"
        *ngIf="confirmaDelete"
      ></ox-button>

      <ox-button
        class="mt-5"
        label="PRINT"
        (onClick)="printObject()"
        *ngIf="!confirmaDelete"
      ></ox-button>
    </div>
  </ng-container>
</div>
