<div class="form-group">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>

  <select [name]="id" [id]="id" [(ngModel)]="inputValue">
    <option [value]="item[campoChave]" *ngFor="let item of itens">
      {{ item[campoTexto] }}
    </option>
  </select>
  <!--  -->
  <!--  <select class="form-control margem-form" [id]="id" autocomplete="off" [(ngModel)]="inputValue">-->
  <!--    <option *ngIf="! permiteVazio" selected hidden style='display: none' value=''></option>-->
  <!--    <option *ngIf="permiteVazio" selected value=''></option>-->
  <!--    <option *ngFor="let item of itens" [ngValue]="item[campoChave]">{{item[campoTexto]}}</option>-->
  <!--  </select>-->
  <!-- <label [id]="feedback_id()"></label> -->
</div>
