import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { FirebaseApiService } from "src/app/services/firebase.service";
import { FormTemplate } from "src/app/services/form.service";
import { LogoComponent } from "src/app/components/logo/logo.component";
import { ZenKaoxApiService } from "../../services/zen-kaox-api.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-cms",
  templateUrl: "./cms.component.html",
  styleUrls: ["./cms.component.scss"],
})
export class CMSAdminComponent implements OnInit {
  public dadosBASE: any;
  public dadosITEM: any;
  public teste: any;

  itensSelect = [
    {
      valor: "INPUT",
      nome: "input",
    },
    {
      valor: "TOGGLE",
      nome: "toggle",
    },
    {
      valor: "RADIO",
      nome: "radio",
    },
    {
      valor: "SELECT",
      nome: "select",
    },

    {
      valor: "TEXTAREA",
      nome: "textarea",
    },
    {
      valor: "EDITOR",
      nome: "editor",
    },
    {
      valor: "CHECKLIST",
      nome: "checklist",
    },

    {
      valor: "FILE",
      nome: "file",
    },

    {
      valor: "COLOR",
      nome: "color",
    },

    {
      valor: "NUMBER",
      nome: "number",
    },



  ];

  itensCheckbox = ["opcao 111", "opcao 22", "opcao 3", "opcao 04"];
  configBase: FormTemplate[];
  configFormMaster: FormTemplate[];


  listaModelos: any[];
  listaBases: any[];


  listaInputs: any[];
  pronto: boolean;

  constructor(public firebase: FirebaseApiService, public firestore: AngularFirestore, private fb: FormBuilder) {

    this.loadData();

  }

  ngOnInit(): void {


    this.configFormMaster = [
      {
        nome: "nome",
        label: "nome",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "label",
        label: "label",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "obrigatorio",
        label: "obrigatorio",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },

      {
        nome: "tipo",
        label: "tipo",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: this.itensSelect,
        form: true,
      },

      {
        nome: "campoTexto",
        label: "campoTexto",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "formato",
        label: "formato",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "valor",
        label: "valor",
        obrigatorio: false,
        tipo: "TEXTAREA",
        form: true,
      },

      {
        nome: "opcoes",
        label: "opcoes",
        obrigatorio: false,
        tipo: "TEXTAREA",
        form: true,
      },


      {
        nome: "coluna",
        label: "coluna",
        obrigatorio: false,
        tipo: "NUMBER",
        form: true,
      },


      {
        nome: "ordem",
        label: "ordem",
        obrigatorio: false,
        tipo: "NUMBER",
        form: true,
      },

      {
        nome: "form",
        label: "form",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },
    ];


  }

  loadData(): void {

    this.firebase.getCollectionData('forms').subscribe((res) => {
      console.log(res);
      this.listaModelos = [];

      res.forEach((form) => {
        this.listaModelos.push({
          valor: form.path,
          nome: form.nome,
        });
      });

      this.setupForm();
    });


    this.firebase.getCollectionData('BASE_CMS').subscribe((res) => {
      console.log(res);
      this.listaBases = res;

    });

  }


  setupForm(): void {

    this.configBase = [
      {
        nome: "nome",
        label: "nome",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },
      {
        nome: "modelo",
        label: "modelo",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: this.listaModelos,
        form: true,
      },

      {
        nome: "desc",
        label: "desc",
        obrigatorio: true,
        tipo: "TEXTAREA",
        form: true,
      },

      {
        nome: "visivel",
        label: "visivel",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },

      {
        nome: "colecao",
        label: "colecao",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },
    ];

    this.pronto = true;
  }



  //CRUD FORM
  readForm(item: any): void {

    this.firebase.getItemData(item.path).subscribe((res) => {
      console.log('formBase', res);

      this.dadosBASE = res;

      this.firebase.getCollectionData(this.dadosBASE.modelo + "/itens").subscribe((mod) => {

        console.log('modeloXXXX', mod);
        mod.sort((a, b) => a.ordem - b.ordem);
        this.configFormMaster = mod;
        //  this.dadosBASE = res;
      });
    });

    this.firebase.getCollectionData(item.path + '/itens').subscribe((res) => {

      console.log('itens', res);
      this.listaInputs = res;

      this.teste = {};
      this.listaInputs.forEach((tf) => {
        this.teste[tf.nome] = tf.valor;
      });
    });
  }

  saveForm(item: any): void {

    if (this.dadosBASE?.path) {
      this.firestore.doc<any>(this.dadosBASE?.path).update(item);
    } else {
      this.createForm(item);
    }

  }

  createForm(item: any): void {
    // item[item.colecao] = [];
    this.firebase.addItemToCollection('BASE_CMS', item);
  }

  clearForm(): void {
    this.dadosITEM = null;
    this.dadosBASE = null;

  }

  cloneForm(): void {
    this.dadosBASE.path = null;
    this.dadosBASE.nome += ' clone';
    this.dadosBASE.label += ' clone';
  }

  deleteForm(): void {
    this.firestore.doc<any>(this.dadosBASE?.path).delete();
  }



  //CRUD INPUT

  readInput(item: any): void {
    this.firebase.getItemData(item.path).subscribe((res) => {

      console.log('DADOS ITEM', res);

      this.dadosITEM = res;
      // this.testeForm.forEach((tf) => {
      //   this.teste[tf.nome] = tf.valor;
      // });
    });
  }

  saveInput(item: any): void {

    if (this.dadosITEM?.path) {
      this.firestore.doc<any>(this.dadosITEM?.path).update(item);
    } else {
      this.createInput(item);
    }
  }

  createInput(item: any): void {
    this.dadosITEM = item;

    this.firebase.addItemToCollection(this.dadosBASE.path + '/itens', item);
    this.clearInput();
  }

  clearInput(): void {
    this.dadosITEM = null;
  }

  cloneInput(): void {
    this.dadosITEM.path = null;
    this.dadosITEM.nome += ' novo';
    this.dadosITEM.label += ' novo';
  }

  deleteInput(): void {
    this.firestore.doc<any>(this.dadosITEM?.path).delete();
  }



  //EXPORT
  exportarForm(): void {

    console.log("///////////////////////////////");
    console.log("///////////////////////////////");
    console.log(JSON.stringify(this.listaInputs));
    console.log("///////////////////////////////");
    console.log("///////////////////////////////");

  }
}
