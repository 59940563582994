import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ZenAlbumRaw, ZenItem, ZenKaoxApiService } from 'src/app/services/zen-kaox-api.service';

import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseApiService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  id: string;
  album: ZenAlbumRaw;
  itens: ZenItem[];
  url = "http://kaox.tv/cache/";
  rows: any;
  atual: any;
  // colornization/loop_23.jpg_250.jpg"

  firebasePath = 'PROJECTS/hqL3P6H4poWPPfqXSLj0';
  albumPath: any;
  modoFirebase = true;


  constructor(public firebase: FirebaseApiService, public api: ZenKaoxApiService, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.itens = [];

    this.route.params.subscribe((params) => {

      this.id = params.id;

      console.log(this.id);

      this.loadData();

    });
  }


  loadData(): void {


    if (this.modoFirebase) {

      this.firebase.getC(this.firebasePath, 'albums').subscribe((base) => {

        console.log('BASeeeeeeeeeeeeeeeeeeeeeEE', base);

        let selec = base.filter((item) => {
          return item.id == this.id;
        });

        console.log(selec, 'seleeeeelelelelelec');
        this.album = selec[0];
        this.albumPath = selec[0].album;

        this.firebase.getC(this.albumPath, 'photos').subscribe((rows) => {

          this.rows = rows;

          this.itens = [];
          let folder = this.url + this.album.folder + '/';
          let extension = '_250.jpg';

          console.log('1 item', rows);

          rows.forEach(item => {

            item.imageSrc = this.sanitizer.bypassSecurityTrustUrl(folder + item.filename + extension);
            this.itens.push(item);

          });

          console.log(this.itens);

        })
        // PROJECTS/hqL3P6H4poWPPfqXSLj0/albums/4lrIkVndS8QybcR1qQLj/photos/ONUjZwlEnc4R8k7eNHJb

      });

    } else {

      this.api.getAlbum(this.id).subscribe((res: any) => {

        console.log("GET ALBUM:  ", res);
        this.album = res.album as ZenAlbumRaw;

        this.rows = res.rows;

        this.itens = [];
        let folder = this.url + this.album.folder + '/';
        let extension = '_250.jpg';

        res.rows.forEach(item => {

          item.imageSrc = this.sanitizer.bypassSecurityTrustUrl(folder + item.filename + extension);
          this.itens.push(item);

        });

        console.log(this.itens);

      });

    }



  }

  abrir(event: any): void {

    console.log(event);

    this.atual = event;

  }

}
