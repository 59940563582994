<div id="superior">
  <div id="gallerytitle">
    {{title}}
	</div>
  <div id="menu">

    <ng-container *ngFor="let item of menu">
      <a class="mx-2" (click)="toggleMenu(item.type)">-&gt;{{item.label}}</a>
    </ng-container>

		<!-- <a (click)="toggleLinks()">-&gt;conexões</a> -->
	</div>
</div>


<div id="homeAbout" *ngIf="showAbout" (click)="toggleMenu('about')" class="mt-5 p-4 animate__animated animate__fadeIn">
  <h1>{{chapter}}</h1>
  <p>{{ textAbout }}</p>
</div>


<div id="homeLinks" *ngIf="showLinks" (click)="toggleMenu('extra')" class="animate__animated animate__fadeIn">

  <ul>
    <li *ngFor="let item of links">
		  <a [href]="item.link">{{ item.label }}</a>
    </li>
  </ul>

</div>


