<button
  type="button"
  [class]="estilo"
  (click)="internalClick($event)"
  #button
  [id]="id"
  [disabled]="disabled && ativo"
  data-toggle="tooltip"
  data-placement="bottom"
  [title]="title ? title : label"
>
  <img *ngIf="imagem" [src]="imagem" alt="" />
  <i class="material-icons-outlined icone" *ngIf="icone != undefined">{{ icone }}</i>
  <div
    [ngClass]="{ 'ajuste-icone': icone != undefined && label != undefined }"
    *ngIf="label != undefined"
  >
    {{ label }}
  </div>
</button>
