import { Component, Input, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const OX_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxSelectComponent),
  multi: true,
};

@Component({
  selector: "ox-select",
  templateUrl: "./ox-select.component.html",
  styleUrls: ["./ox-select.component.scss"],
  providers: [OX_SELECT_VALUE_ACCESSOR],
})
export class OxSelectComponent implements ControlValueAccessor {
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};
  internalInputValue: any = "";

  @Input() label = "";
  @Input() placeholder = "";
  @Input() id: string = null;
  @Input() permiteVazio = false;
  @Input() requerido = false;
  @Input() campoChave = "id";
  @Input() campoTexto = "nome";
  @Input() largura: string = null;

  desativado = false;

  @Input() itens;

  feedback_id(): string {
    if (this.id) {
      return "feedback_" + this.id;
    } else {
      return null;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {

    console.log(value, this.campoChave, this.itens);


    if (value !== this.internalInputValue) {
      this.internalInputValue = value;

      if (this.onChangeCallback) {
        this.onChangeCallback(value);
      }
      if (this.onTouchedCallback) {
        this.onTouchedCallback();
      }
    }
  }

  // public writeValue(value: any): void {
  //   this.internalInputValue = value;
  // }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.itens) {
      if (typeof this.itens === 'string') {
        this.itens = JSON.parse(this.itens);
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.desativado = isDisabled;
  }
}
