import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { FirebaseApiService } from "src/app/services/firebase.service";
import { FormOptions } from "src/app/services/form.service";
import { LogoComponent } from "src/app/components/logo/logo.component";
import { ZenKaoxApiService } from "../../services/zen-kaox-api.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-base-cms',
  templateUrl: './base-cms.component.html',
  styleUrls: ['./base-cms.component.scss']
})
export class BaseCmsComponent implements OnInit {

  @Input() base = 'BASE_CMS';
  listaBases: any[];

  selectBases: FormOptions[];

  // @Output() gravar = new EventEmitter();
  pronto: boolean;
  showEditor: boolean = true;


  constructor(public firebase: FirebaseApiService, public firestore: AngularFirestore, private fb: FormBuilder) {

    this.selectBases = [{
      valor: this.base,
      nome: this.base
    },
    {
      valor: 'forms',
      nome: 'forms',
    },
    {
      valor: 'PROJECTS',
      nome: 'PROJECTS',
    }
    ];

  }

  ngOnInit(): void {

    this.loadData();
  }

  loadData(): void {

    console.log('BASE', this.base);
    this.firebase.getCollectionData(this.base).subscribe((res) => {
      this.listaBases = res;

    });

  }


  open(item: any): void {

    let path = item.split('/');
    let name;
    let collection = '';

    for (let index = 0; index < path.length - 1; index++) {
      collection += path[index] + '/';
      name = path[index];
    }

    this.selectBases.push({
      valor: collection,
      nome: name
    });

    console.log(item, this.selectBases);
    this.base = collection;
    // this.base = 'BASE_CMS/fiPnZcncnYGplUpjUYfp/itens/Esa2gIjvpXANqWjyPobD/collection'
    this.loadData();
  }


  toggleShow(): void {

    this.showEditor = !this.showEditor;

  }


}
