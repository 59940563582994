<div>
  <label class="d-flex"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>
  <div class="mb-3" style="display: flex; flex-wrap: wrap">
    <div
      *ngFor="let item of options; let i = index"
      class="form-check ml-2 mb-2"
    >
      <input
        class="form-check-input"
        type="checkbox"
        [value]="item.value"
        [name]="item.value"
        [checked]="item.checked"
        (change)="selected($event, i)"
        [disabled]="internalDisabled"
      />
      <label class="form-check-label" [for]="item.value">
        {{ item.value }}
      </label>
    </div>
  </div>
</div>
