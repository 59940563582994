import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormService, FormTemplate } from "src/app/services/form.service";

import { Clipboard } from '@angular/cdk/clipboard';
import { FirebaseApiService } from "src/app/services/firebase.service";

@Component({
  selector: 'app-item-cms',
  templateUrl: './item-cms.component.html',
  styleUrls: ['./item-cms.component.scss']
})
export class ItemCmsComponent implements OnInit {

  @Input() item = null;
  @Input() form: FormTemplate[] = null;

  @Input() base = null;

  @Output() openCollection = new EventEmitter();
  pronto: boolean;
  editar: boolean = false;
  lista: boolean = false;

  configBase: FormTemplate[];


  dadosBASE: any;
  confirmaDelete: boolean;


  constructor(
    private formService: FormService,
    private clipboard: Clipboard,
    public firebase: FirebaseApiService,
    public firestore: AngularFirestore) { }

  ngOnInit(): void {

    if (this.item) this.readItem(this.item);
    else this.pronto = true;

  }

  toggleSetup(item: any): void {
    this.logPath();
    this.editar = !this.editar;
  }

  toggleList(): void {
    this.lista = !this.lista;
  }

  inputAction(event: any): void {
    if (event.type === 'collection') {
      this.openCollection.emit(event.value);
    }
  }

  //CRUD INPUT
  readItem(item: any): void {
    this.firebase.getItemData(item.path).subscribe((res) => {
      this.dadosBASE = res;
      this.pronto = true;
    });
  }

  saveItem(item: any): void {

    if (this.dadosBASE?.path) {
      this.firestore.doc<any>(this.dadosBASE?.path).update(item);
    } else {
      this.createItem(item);
    }
  }

  createItem(item: any): void {
    this.dadosBASE = item;

    this.firebase.addItemToCollection(this.base, item);
    this.clearItem();


  }

  clearItem(): void {
    this.dadosBASE = null;
  }

  cloneItem(): void {
    this.dadosBASE.path = null;
    this.dadosBASE.nome += ' novo';
    this.dadosBASE.label += ' novo';
  }

  deleteItem(): void {
    this.firestore.doc<any>(this.dadosBASE?.path).delete();
    this.confirmaDelete = false;
  }
  toggleDelete(): void {
    this.confirmaDelete = !this.confirmaDelete;
  }


  logPath(): void {
    this.clipboard.copy(this.dadosBASE?.path);
    console.log('loading item: ', this.dadosBASE?.path);
  }


  printObject(): void {

    this.clipboard.copy(this.dadosBASE);
    console.log('print item: ', this.dadosBASE);

  }

}
