<div class="form-group">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>
  <div class="row px-3" style="max-width: 100%">
    <div class="d-flex align-items-right" *ngFor="let item of itens">
      <input
        class="m-2"
        [id]="id + item.valor"
        type="radio"
        [name]="id + item.valor"
        [value]="item.valor"
        [(ngModel)]="inputValue"
        style="width: 18px; height: 18px"
      />
      <label
        [for]="id + item.valor"
        style="font-size: 1.2em; min-width: 90px; text-align: center"
        >{{ item.nome }}</label
      >
    </div>
  </div>
  <label [id]="feedback_id()"></label>
</div>
