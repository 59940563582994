import { Component, ContentChild, Input, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const SIMPLE_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxToggleComponent),
  multi: true,
};


@Component({
  selector: 'ox-toggle',
  templateUrl: './ox-toggle.component.html',
  styleUrls: ['./ox-toggle.component.scss'],
  providers: [SIMPLE_INPUT_VALUE_ACCESSOR],

})
export class OxToggleComponent implements ControlValueAccessor {
  internalInputValue: any = '';
  internalDisabled = false;
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @Input() label = '';
  @Input() placeholder = '';
  @Input() id: string = null;
  @Input() obrigatorio = false;

  @Input() mask;
  @Input() req = true;

  // Estas propriedades recuperar os ng-templates inseridos dentro da tag do componente onde ele está sendo usado
  // para serem inseridas usando ngTemplateOutlet
  @ContentChild('avisos', { static: false }) avisos: TemplateRef<any>;
  @ContentChild('erros', { static: false }) erros: TemplateRef<any>;
  rdmId: number;

  constructor() {

    this.rdmId = Math.floor(Math.random() * 100);

  }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    this.req = false;
    if (this.obrigatorio !== undefined) {
      if (value) {
        this.obrigatorio = false;
      } else {
        this.obrigatorio = true;
      }
    }
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  feedback_id(): string {
    if (this.id) {
      return 'feedback_' + this.id;
    } else {
      return null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.internalDisabled = isDisabled;
  }
}
