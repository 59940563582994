import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormOptions, FormService, FormTemplate } from "src/app/services/form.service";

import { FirebaseApiService } from "src/app/services/firebase.service";
import { LogoComponent } from "src/app/components/logo/logo.component";
import { ZenKaoxApiService } from "../../services/zen-kaox-api.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  private formsCollection: AngularFirestoreCollection<any>;
  public dadosBASE: any;
  public dadosITEM: any;
  public teste: any;

  itensSelect: FormOptions[];

  itensCheckbox = ["opcao 111", "opcao 22", "opcao 3", "opcao 04"];
  configBase: FormTemplate[];
  configFormMaster: FormTemplate[];


  modelo: any;
  importar: any;
  importarItem: any;




  testeForm: FormTemplate[];
  listaForms: any[];
  listaInputs: any[];
  listaImport: any[];
  showDelete: boolean;

  constructor(public firebase: FirebaseApiService, public firestore: AngularFirestore, private fb: FormBuilder, private formService: FormService) {


    this.itensSelect = formService.getFormOptions();
    firebase.getCollectionData('forms').subscribe((res) => {
      console.log(res);
      this.listaForms = res;
    })

  }

  ngOnInit(): void {
    this.configBase = [
      {
        nome: "nome",
        label: "nome",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "desc",
        label: "desc",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "visivel",
        label: "visivel",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },

      {
        nome: "colecao",
        label: "colecao",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },
    ];

    this.configFormMaster = this.formService.createTemplateForm();

    // campoTexto?: string;
    //   formato?: string;
    //   valor?: any;
    //   opcoes?: any;

    this.testeForm = [
      {
        nome: "teste",
        label: "TESTE",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },
    ];

    // this.dadosITEM = {
    //   nome: "novo",
    //   label: "NOVO",
    //   obrigatorio: false,
    //   tipo: "INPUT",
    //   form: true,
    //   opcoes: testeParse,
    // };

    // this.dadosBASE = {
    //   nome: "novo",
    //   desc: "NOVO",
    //   visivel: false,
    // }
  }




  //CRUD FORM

  loadForm(): void {

    console.log(this.modelo);
    this.readForm({ path: this.modelo });

  }

  readForm(item: any): void {

    this.firebase.getItemData(item.path).subscribe((res) => {
      this.dadosBASE = res;
    });


    this.firebase.getCollectionData(item.path + '/itens').subscribe((res) => {

      res.sort((a, b) => a.ordem - b.ordem);
      this.listaInputs = res;


      this.teste = {};
      this.testeForm = [];
      this.listaInputs.forEach((tf) => {

        if (tf.opcoes) {
          tf.opcoes = JSON.parse(tf.opcoes);
        }
        this.testeForm.push(tf);
        this.teste[tf.nome] = tf.valor;
      });

      console.log(this.teste);
    });
  }

  saveForm(item: any): void {

    if (this.dadosBASE?.path) {
      this.firestore.doc<any>(this.dadosBASE?.path).update(item);
    } else {
      this.createForm(item);
    }

  }

  createForm(item: any): void {
    item[item.colecao] = [];
    this.firebase.addItemToCollection('forms', item);
  }

  clearForm(): void {
    this.dadosBASE = null;
  }

  cloneForm(): void {
    this.dadosBASE.path = null;
    this.dadosBASE.nome += ' clone';
    this.dadosBASE.label += ' clone';
  }

  deleteForm(): void {
    this.firestore.doc<any>(this.dadosBASE?.path).delete();
  }

  toggleDelete(): void {
    this.showDelete = !this.showDelete;
  }

  printForm(): void {

    console.log('print form: ', this.dadosBASE);
    console.log('print inputs: ', this.listaInputs);

  }



  //CRUD INPUT

  readInput(item: any): void {

    console.log(item);


    this.firebase.getItemData(item.path).subscribe((res) => {

      console.log('dados item', res);

      this.dadosITEM = res;

      this.testeForm.forEach((tf) => {
        this.teste[tf.nome] = tf.valor;
      });
    });
  }

  saveInput(item: any): void {

    if (this.dadosITEM?.path) {
      this.firestore.doc<any>(this.dadosITEM?.path).update(item);
    } else {
      this.createInput(item);
    }
  }

  createInput(item: any): void {
    this.dadosITEM = item;

    this.firebase.addItemToCollection(this.dadosBASE.path + '/itens', item);
    this.clearInput();
  }

  clearInput(): void {
    this.dadosITEM = null;
  }

  cloneInput(): void {
    this.dadosITEM.path = null;
    this.dadosITEM.nome += ' novo';
    this.dadosITEM.label += ' novo';
  }

  deleteInput(): void {
    this.firestore.doc<any>(this.dadosITEM?.path).delete();
  }


  importFrom(): void {

    this.firebase.getCollectionData(this.importar + '/itens').subscribe((res) => {

      res.sort((a, b) => a.ordem - b.ordem);
      // res.forEach((r) => {
      //   r.path = "";
      // });

      this.listaImport = res;

      console.log(this.listaImport);

    });
  }


  importFromItem(): void {


    this.firebase.getItemData(this.importarItem).subscribe((res: any) => {

      console.log(res);
      res.path = null;
      this.createInput(res);

    });
  }

  //EXPORT
  exportarForm(): void {

    console.log("///////////////////////////////");
    console.log("///////////////////////////////");
    console.log(JSON.stringify(this.listaInputs));
    console.log("///////////////////////////////");
    console.log("///////////////////////////////");

  }
}
