import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseApiService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-proto',
  templateUrl: './proto.component.html',
  styleUrls: ['./proto.component.scss']
})
export class ProtoComponent implements OnInit {

  base = "BASE_CMS/hsVTP0HsKPChBzfvxbrP";

  items: any;
  content: any[];
  loaded: boolean;

  constructor(public firebase: FirebaseApiService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.loadData();


  }


  loadData(): void {

    this.firebase.getC(this.base, 'content').subscribe((base) => {

      console.log('BASeeeeeeeeeeeeeeeeeeeeeEE', base);
      this.content = [];

      this.content = this.firebase.sortCollection(base);
      this.content = this.firebase.filterVisibility(this.content);


      this.loaded = true;

    });

  }


}
