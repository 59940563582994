<p>tags works!</p>
<div id="tags">
  <ul class="taglist">
    <li *ngFor="let tag of tags">
      <a href="/portfolio/index.php?p=search&amp;searchfields=4&amp;words=3d"
        style="font-size:0.8em;"
        rel="nofollow">{{tag.name}}
      </a>
    </li>
  </ul>
</div>
