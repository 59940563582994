import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScrollToAnimationEasing, ScrollToConfigOptions, ScrollToOffsetMap, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

import { ScrollToEvent } from '@nicky-lenaers/ngx-scroll-to/lib/scroll-to-event.interface';

@Component({
  selector: 'app-content-clip',
  templateUrl: './content-clip.component.html',
  styleUrls: ['./content-clip.component.scss']
})
export class ContentClipComponent implements OnInit {

  @Input() content: any;
  @Output() trigger = new EventEmitter();


  constructor(private scrollToService: ScrollToService) {

  }

  ngOnInit(): void {
  }

  triggerNext(): void {

    console.log('next');

    this.trigger.emit('next');

  }

}
