import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SafeResourceUrl } from '@angular/platform-browser';

export interface galleryObj {

  name?:  string;
  id?:  number;
  background?:  SafeResourceUrl;
  tags?: string[];

  desc?:  string;
  date?:  string;
  custom_data?:  string;
  folder?:  string;

  url?: string

}

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss']
})
export class GalleryViewComponent implements OnInit {

  @Output() fechar = new EventEmitter();
  @Input() list : galleryObj[];

  @Input() url = "http://kaox.tv/albums/";
  @Input() folder : string;

  actual = 0;

  constructor() { }

  ngOnInit(): void {
  }

  view(slide: number) {

    console.log(this.list[slide].name, this.list[slide].url);


    this.actual = null;

    this.actual = slide;

  }

}
