import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ZenKaoxApiService, ZenProject } from 'src/app/services/zen-kaox-api.service';

export interface ProjectOX {
  id: string;
  title?: string;
  thumb?: string;
  folder?: string;
  url?: SafeResourceUrl;
  bgo?: SafeResourceUrl;
  tags?: [
    any
  ];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  projects: ProjectOX[] = [];
  loaded: boolean;

  albumPath = "http://kaox.tv/cache/";

  constructor(public api:ZenKaoxApiService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    console.log("TESTE");

    // this.projects = [];

    this.api.getAllProjects().subscribe((res: ZenProject)=> {

      console.log(res);
      res.rows.forEach(row => {

        let url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.albumPath}/${row.folder}/${row.thumb}_250.jpg`);

        console.log(url);


        this.projects.push(
          {
            id: row.id,
            title: row.title,
            thumb: row.thumb,
            folder: row.folder,
            url,
            // bgo: string;
            tags: row.tags,
          }
        );

        console.log(row);


      });

      this.loaded = true;

      console.log(this.projects);


      // const imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${img.url}`);


    });

    this.api.getAllTags().subscribe((res)=> {

      console.log("TAGSS:  " , res);

    });


    this.api.getAlbum(37).subscribe((res)=> {

      console.log("CNTSSS:  " , res);

    });


  }

  openProject(id: any): void {

    console.log(id);

  }

}
