import { Component, OnInit } from '@angular/core';

import { ZenKaoxApiService } from '../../services/zen-kaox-api.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(public api: ZenKaoxApiService) { }

  ngOnInit() {

    console.log("TESTE album");


    this.api.getAllProjects().subscribe((res) => {

      console.log(res);

    });

    this.api.getAllTags().subscribe((res) => {

      console.log("TAGSS:  ", res);

    });


    this.api.getAlbum(37).subscribe((res) => {

      console.log("CNTSSS:  ", res);

    });


  }

}
