import { Observable, ReplaySubject, Subject } from 'rxjs';

import { FirebaseApiService } from './firebase.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {


  private forms$: Subject<any> = new ReplaySubject<any>(1);
  collectionFormLoaded: any;
  collectionForm: ({
    nome: string;
    label: string;
    obrigatorio: boolean;
    tipo: string;
    form: boolean;
    opcoes?: undefined;
  } | {
    nome: string;
    label: string;
    obrigatorio: boolean;
    tipo: string;
    opcoes: any;
    form: boolean;
  })[];



  constructor(public firebase: FirebaseApiService) { }


  public getCollectionForm(): Observable<any> {
    if (!this.collectionFormLoaded) {
      this.loadCollectionForm();
    }
    return this.forms$;
  }

  public loadCollectionForm(): void {

    this.firebase.getCollectionData('forms').subscribe((res) => {
      // console.log(res);
      let listaModelos = [];

      res.forEach((form) => {
        listaModelos.push({
          valor: form.path,
          nome: form.nome,
        });
      });

      this.createCollectionForm(listaModelos);
    });
  }


  createCollectionForm(options: any[]): void {

    this.collectionForm = [
      {
        nome: "name",
        label: "name",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },
      {
        nome: "desc",
        label: "desc",
        obrigatorio: true,
        tipo: "TEXTAREA",
        form: true,
      },

      {
        nome: "type",
        label: "type",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: this.getTypeOptions(),
        form: true,
      },
      {
        nome: "modelo",
        label: "modelo",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: options,
        form: true,
      },


      {
        nome: "visivel",
        label: "visivel",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },

      {
        nome: "colecao",
        label: "colecao",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },
    ];


    this.forms$.next(this.collectionForm);
    this.collectionFormLoaded = true;
  }



  createTemplateForm(): FormTemplate[] {

    return [
      {
        nome: "nome",
        label: "nome",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "label",
        label: "label",
        obrigatorio: true,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "obrigatorio",
        label: "obrigatorio",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },

      {
        nome: "tipo",
        label: "tipo",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: this.getFormOptions(),
        form: true,
      },

      {
        nome: "campoTexto",
        label: "campoTexto",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "campoChave",
        label: "campoChave",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "formato",
        label: "formato",
        obrigatorio: false,
        tipo: "INPUT",
        form: true,
      },

      {
        nome: "valor",
        label: "valor",
        obrigatorio: false,
        tipo: "TEXTAREA",
        form: true,
      },

      {
        nome: "opcoes",
        label: "opcoes",
        obrigatorio: false,
        campoTexto: 'nome',
        campoChave: 'id',
        tipo: "LIST",
        form: true,
      },


      {
        nome: "coluna",
        label: "coluna",
        obrigatorio: false,
        tipo: "SELECT",
        opcoes: this.getColOptions(),
        form: true,
      },


      {
        nome: "ordem",
        label: "ordem",
        obrigatorio: false,
        tipo: "NUMBER",
        form: true,
      },

      {
        nome: "form",
        label: "form",
        obrigatorio: false,
        tipo: "TOGGLE",
        form: true,
      },
    ];


  }


  getItemTemplate(): any {



  }


  getFormOptions(): FormOptions[] {

    return [
      {
        valor: "INPUT",
        nome: "input",
      },
      {
        valor: "TOGGLE",
        nome: "toggle",
      },
      {
        valor: "RADIO",
        nome: "radio",
      },
      {
        valor: "SELECT",
        nome: "select",
      },

      {
        valor: "TEXTAREA",
        nome: "textarea",
      },
      {
        valor: "EDITOR",
        nome: "editor",
      },
      {
        valor: "CHECKLIST",
        nome: "checklist",
      },

      {
        valor: "LIST",
        nome: "list",
      },

      {
        valor: "FILE",
        nome: "file",
      },

      {
        valor: "COLOR",
        nome: "color",
      },

      {
        valor: "NUMBER",
        nome: "number",
      },

      {
        valor: "COLLECTION",
        nome: "collection",
      },
    ];

  }

  getTypeOptions(): FormOptions[] {

    return [
      {
        valor: "IMAGE",
        nome: "image",
      },
      {
        valor: "VIDEO",
        nome: "video",
      },
      {
        valor: "TOKEN",
        nome: "token",
      },
      {
        valor: "CODE",
        nome: "code",
      },
      {
        valor: "PROJECT",
        nome: "project",
      },
    ];

  }

  getColOptions(): FormOptions[] {

    return [
      {
        valor: "col-12",
        nome: "COL 12",
      },
      {
        valor: "col-6",
        nome: "COL 6",
      },
      {
        valor: "col-4",
        nome: "COL 4",
      },
      {
        valor: "col-3",
        nome: "COL 3",
      },
      {
        valor: "col-2",
        nome: "COL 2",
      },
      {
        valor: "col-1",
        nome: "COL 1",
      },
      {
        valor: "col",
        nome: "COL",
      },
    ];

  }

}

export interface FormOptions {
  valor: string,
  nome: string
}

export interface FormTemplate {
  nome: string;
  label: string;
  obrigatorio: boolean;
  tipo: string;
  form: boolean;
  campoTexto?: string;
  campoChave?: string;
  formato?: string;
  valor?: any;
  opcoes?: any;
}
