import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-thumb',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss']
})
export class ThumbComponent implements OnInit {

  @Input() data: any;

  @Input() type = 'menu';
  @Input() icone = undefined;
  @Input() mostrarBotao = true;

  @Output() open = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

}
