import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { AboutComponent } from './pages/about/about.component';
import { AlbumComponent } from './pages/album/album.component';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './services/auth.service';
import { BaseComponentsModule } from './base/base.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CMSAdminComponent } from './pages/cms/cms.component';
import { ControllerComponent } from './components/controller/controller.component';
import { FirebaseComponent } from './pages/firebase/firebase.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormComponent } from './pages/form/form.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { GalleryViewComponent } from './components/gallery-view/gallery-view.component';
import { GridMenuComponent } from './components/grid-menu/grid-menu.component';
import { GridSubmenuComponent } from './components/grid-submenu/grid-submenu.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { InterfaceComponentsModule } from './interface/interface.module';
import { LoginComponent } from './pages/login/login.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { NgModule } from '@angular/core';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ProjectComponent } from './pages/project/project.component';
import { ProtoComponent } from './pages/proto/proto.component';
import { PrototypesComponentsModule } from './prototypes/prototypes.module';
import { RootComponent } from './pages/root/root.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ShowItemComponent } from './components/show-item/show-item.component';
import { TagsComponent } from './components/tags/tags.component';
import { TestComponent } from './pages/test/test.component';
import { ThumbComponent } from './components/thumb/thumb.component';
import { TokensComponent } from './pages/tokens/tokens.component';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AboutComponent,
    AlbumComponent,
    AppComponent,
    CMSAdminComponent,
    ControllerComponent,
    FirebaseComponent,
    FooterComponent,
    FormComponent,
    GalleryComponent,
    GalleryViewComponent,
    GridMenuComponent,
    GridSubmenuComponent,
    HeaderComponent,
    HomeComponent,
    ProtoComponent,
    LoginComponent,
    LogoComponent,
    MenuComponent,
    PortfolioComponent,
    ProjectComponent,
    RootComponent,
    ShowItemComponent,
    TagsComponent,
    TestComponent,
    ThumbComponent,
    TokensComponent,
  ],
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    InterfaceComponentsModule,
    BaseComponentsModule,
    PrototypesComponentsModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    HttpClient,
    AuthService,
    { provide: BUCKET, useValue: 'kaox-tv.appspot.com' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
