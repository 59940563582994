<h1>base-render works!</h1>

<app-render-bgo id="bgo" [style.bottom]="bgoPivot"></app-render-bgo>
<app-render-layer1 id="layer1" [style.bottom]="layer1Pivot"></app-render-layer1>
<app-render-layer2 id="layer2" [style.bottom]="layer2Pivot"></app-render-layer2>

<div class="row" id="content" *ngIf="content">
  <ng-container *ngFor="let item of content">
    <div
    [id]="item.title"
    class="w-100 text-center col-12 p-5"
    style="height: 100vh"
    >
    <app-content-clip [content]="item" [style.bottom]="contentPivot" (trigger)="triggerToNext($event)"></app-content-clip>
  </div>
</ng-container>
</div>

<app-render-overlay id="overlay" [style.bottom]="overlayPivot"></app-render-overlay>
