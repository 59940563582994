<div
  class="main px-5 pt-5"
  style="background-color: #999999; min-height: 100px"
>
  <div class="row">
    <div class="col-4">
      <h1>
        <p>firebase works!</p>
      </h1>
    </div>
    <div class="col-4 mt-2">
      <input type="file" (change)="uploadFile($event)" />
      <div>{{ uploadPercent | async }}</div>
      <a [href]="downloadURL | async">{{ downloadURL | async }}</a>

      <h3>{{ album?.title }}</h3>
    </div>
    <div class="col-4 d-inline text-right">
      <ox-button
        class="m-3"
        [label]="btn.name"
        (onClick)="eventoBotao(btn.id)"
        *ngFor="let btn of buttons"
      ></ox-button>
      <hr class="my-3" />
    </div>
  </div>
</div>

<div class="w-100 row p-2">
  <div class="col-2">
    <div *ngFor="let item of albums as Album" class="w-100 m-1 row">
      <div class="col-12" style="font-size: 1.2em">
        <ox-button [label]="item.title" (onClick)="openAlbum(item)"></ox-button>
        <!-- <span>{{ item.title }}</span> -->
      </div>
    </div>
  </div>
  <div class="col-4 p-1" style="background-color: #999999">
    <ox-form
      [configuracao]="dadosAlbum"
      [dados]="album"
      (gravar)="gravandoAlbum($event)"
      gravarLabel="SALVAR"
      *ngIf="album"
    ></ox-form>
  </div>
  <div class="col-2">
    <div *ngFor="let photo of photos" class="w-100 m-1 row">
      <div class="col-12">
        <ox-button
          [label]="photo.title"
          (onClick)="openPhoto(photo)"
        ></ox-button>
      </div>
    </div>
    <hr class="my-3" />
    <ox-button
      class="m-3"
      label="IMPORTAR ITEMS"
      (onClick)="importPhotos()"
      *ngIf="album"
    ></ox-button>
  </div>
  <div class="col-4 p-1" style="background-color: #999999">
    <ox-form
      [configuracao]="dadosPhoto"
      [dados]="photo"
      (gravar)="gravandoPhoto($event)"
      gravarLabel="SALVAR"
      *ngIf="photo"
    ></ox-form>

    <ox-button
      class="mt-5"
      label="APAGAR"
      (onClick)="deletePhoto()"
    ></ox-button>
  </div>
</div>

<!--


<div class="w-100 row p-5">

  <div class="col-4 p-5" style="background-color: #999999;">
  <form [formGroup]="form">

    <ox-input label="Teste" formControlName="teste"></ox-input>
    <ox-toggle label="toggggle" formControlName="toggle"></ox-toggle>
    <ox-textarea label="tarea" formControlName="area"></ox-textarea>
    <ox-select label="select" formControlName="select" [itens]="itensSelect"></ox-select>
    <ox-radio label="radio" formControlName="radio"></ox-radio>
    <ox-checkbox label="checkbox" formControlName="checkbox" [itens]="itensCheckbox"></ox-checkbox>

      <ox-button label="bang" (onClick)="bang()"></ox-button>
    </form>
  </div>
  <div class="col-1"></div>
  <div class="col-4">
    <div class="main p-5">
      <div *ngIf="items$ | async; let items; else loading">
        <ul>
          <li *ngFor="let item of items">
            {{ item.text }} : {{ item.color }}
          </li>
        </ul>
        <div *ngIf="items.length === 0">No results, try clearing filters</div>
      </div>
      <ng-template #loading>Loading&hellip;</ng-template>
      <div>
        <h4>Filter by size</h4>
        <button (click)="filterBySize('small')">Small</button>
        <button (click)="filterBySize('medium')">mid</button>
        <button (click)="filterBySize('large')">Large</button>
        <button (click)="filterBySize(null)" *ngIf="this.sizeFilter$.getValue()">
          <em>clear filter</em>
        </button>
      </div>
      <div>
        <h4>Filter by color</h4>
        <button (click)="filterByColor('red')">Red</button>
        <button (click)="filterByColor('blue')">Blue</button>
        <button (click)="filterByColor('green')">Green</button>
        <button (click)="filterByColor(null)" *ngIf="this.colorFilter$.getValue()">
          <em>clear filter</em>
        </button>
      </div>
    </div>
  </div>
  <div class="col-3">
    <ul>
      <li class="text" *ngFor="let item of items | async">
        <p (click)="addItem(item)">
          {{item.nome}}
        </p>
      </li>
    </ul>
  </div>
</div>

 -->
