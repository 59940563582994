/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input, OnInit, Output } from '@angular/core';

import { EventEmitter } from '@angular/core';

@Component({
  selector: 'ox-button',
  templateUrl: './ox-button.component.html',
  styleUrls: ['./ox-button.component.scss']
})
export class OxButtonComponent implements OnInit {

  @Input() label = undefined;
  @Input() id = null;
  @Input() disabled = false;
  @Input() title = '';
  @Output() onClick = new EventEmitter<any>();

  @Input() estilo = 'default';
  @Input() icone: string = null;
  @Input() imagem: string = null;
  @Input() delay: number = 3000;

  ativo = true;

  constructor() {}

  ngOnInit(): void {
    if (this.title === '' && this.label !== undefined) {
      this.title = this.label;
    }
  }

  internalClick(event): void {
    if (this.ativo) {
      this.onClick.emit(event);
      this.ativo = false;
    }

    setTimeout(() => {
      this.ativo = true;
    }, this.delay);
  }
}
