<form [formGroup]="form" class="row">
  <ng-container *ngFor="let campo of configuracao">
    <div
      class="{{ campo.coluna ? campo.coluna + '' : tamanhoColuna + '' }}"
      *ngIf="campo.form"
    >
      <ox-input
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [requerido]="form.get(campo.nome).invalid"
        *ngIf="campo.tipo === 'INPUT'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-input>

      <ox-input
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [requerido]="form.get(campo.nome).invalid"
        *ngIf="campo.tipo === 'PASSWORD'"
        [internalDisabled]="habilitarCampo(campo)"
        type="password"
      ></ox-input>

      <ox-color
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [requerido]="form.get(campo.nome).invalid"
        *ngIf="campo.tipo === 'COLOR'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-color>

      <ox-number
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [requerido]="form.get(campo.nome).invalid"
        *ngIf="campo.tipo === 'NUMBER'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-number>

      <ox-toggle
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'TOGGLE'"
      ></ox-toggle>

      <ox-select
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [campoChave]="campo.campoChave ? campo.campoChave : 'id'"
        [campoTexto]="campo.campoTexto ? campo.campoTexto : 'nome'"
        *ngIf="campo.tipo === 'SELECT'"
        [itens]="campo.opcoes"
      ></ox-select>

      <ox-checkbox
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'CHECKLIST'"
        [itens]="campo.opcoes"
      ></ox-checkbox>

      <ox-radio
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'RADIO'"
        [itens]="campo.opcoes"
      ></ox-radio>

      <ox-list
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="
          campo.tipo === 'LIST' && (!campo.campoTexto || !campo.campoChave)
        "
      ></ox-list>

      <ox-list
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [campoChave]="campo.campoChave"
        [campoTexto]="campo.campoTexto"
        *ngIf="campo.tipo === 'LIST' && campo.campoTexto && campo.campoChave"
      ></ox-list>

      <ox-textarea
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'TEXTAREA'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-textarea>

      <ox-textarea
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'EDITOR'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-textarea>

      <ox-file
        [id]="campo.nome"
        [label]="campo.label"
        [formControlName]="campo.nome"
        *ngIf="campo.tipo === 'FILE'"
        [internalDisabled]="habilitarCampo(campo)"
      ></ox-file>

      <ox-collection
        [id]="campo.nome"
        [path]="path"
        [label]="campo.label"
        [formControlName]="campo.nome"
        [requerido]="form.get(campo.nome).invalid"
        *ngIf="campo.tipo === 'COLLECTION'"
        [internalDisabled]="habilitarCampo(campo)"
        (open)="send('collection', $event)"
      ></ox-collection>
    </div>
  </ng-container>
</form>
<div class="controle w-100 text-left py-2">
  <ox-button
    id="salvar_paciente"
    [icone]="gravarIcone"
    [label]="gravarLabel"
    (onClick)="gravarForm()"
    [disabled]="form.invalid"
  ></ox-button>
</div>
