<div class="thumb" [routerLink]="'/project/' + data.id" *ngIf="type === 'menu'">
  <img [src]="data.url" [alt]="data.title">
  <div class="overlay">
    <div class="bgo">
      <div class="controle p-3">
        <h2>
          {{data.title}}
        </h2>
      </div>
    </div>
  </div>
</div>
<!-- <div class="albumdesc">
  <a (click)="openProject(data.id)">
    [title]="data.title">
    {{data.title}}</a>
</div> -->
<div class="thumb" *ngIf="type === 'submenu'">
  <img [src]="data.imageSrc" [alt]="data.title">
  <div class="overlay" (click)="open.emit(data)">
    <div class="bgo">
      <div class="controle p-3">
        <h3>
          image
          <!-- {{data.title}} -->
        </h3>
      </div>
    </div>
  </div>
</div>
