<div class="show-item"  *ngIf="loaded">
  <div class="overlay">
    <div class="exit" >
      FECHAR
    </div>
  </div>
  <div class="photo" (click)="fechando()">
    <img [src]="fullImage" [alt]="item.title">
  </div>
  <div class="title">
    {{item.title}}
  </div>
</div>
