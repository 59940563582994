

<div class="control" *ngIf="list.length">
  <li *ngFor="let item of list" (click)="view(item.id)" class="links">
    {{item.name}}
  </li>
  <div class="back"></div>
  <div class="next"></div>
</div>

<div class="container" *ngIf="list.length">
  <div class="black">
    <img [src]="list[actual].background">
  </div>
</div>

