import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { OxButtonComponent } from './ox-button/ox-button.component';
import { OxCheckboxComponent } from './ox-checkbox/ox-checkbox.component';
import { OxCollectionComponent } from './ox-collection/ox-collection.component';
import { OxEditorComponent } from './ox-editor/ox-editor.component';
import { OxFileComponent } from './ox-file/ox-file.component';
import { OxFormComponent } from './ox-form/ox-form.component';
import { OxInputColorComponent } from './ox-color/ox-color.component';
import { OxInputComponent } from './ox-input/ox-input.component';
import { OxInputNumberComponent } from './ox-number/ox-number.component';
import { OxListComponent } from './ox-list/ox-list.component';
import { OxRadioComponent } from './ox-radio/ox-radio.component';
import { OxSelectComponent } from './ox-select/ox-select.component';
import { OxTextareaComponent } from './ox-textarea/ox-textarea.component';
import { OxToggleComponent } from './ox-toggle/ox-toggle.component';

@NgModule({
  declarations: [

    OxButtonComponent,
    OxCheckboxComponent,
    OxCollectionComponent,
    OxEditorComponent,
    OxFileComponent,
    OxFileComponent,
    OxFormComponent,
    OxInputColorComponent,
    OxInputComponent,
    OxInputNumberComponent,
    OxRadioComponent,
    OxSelectComponent,
    OxTextareaComponent,
    OxToggleComponent,
    OxListComponent,

  ],
  exports: [

    OxButtonComponent,
    OxCheckboxComponent,
    OxCollectionComponent,
    OxEditorComponent,
    OxFileComponent,
    OxFileComponent,
    OxFormComponent,
    OxInputColorComponent,
    OxInputComponent,
    OxInputNumberComponent,
    OxRadioComponent,
    OxSelectComponent,
    OxTextareaComponent,
    OxToggleComponent,
    OxListComponent,



  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    // NO_ERRORS_SCHEMA
  ],
})
export class InterfaceComponentsModule { }
