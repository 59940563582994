import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showAbout = false;
  showLinks = false;
  showTokens = false;

  menu = [
    {
      label: 'tokens',
      type: 'tokens',
    },
    {
      label: 'extra',
      type: 'extra',
    },
    {
      label: 'about',
      type: 'about',
    },

  ]

  links = [
    {
      label: 'prototypes',
      link: '/prototypes/'
    },
    {
      label: 'ouroboros',
      link: '/ouroboros/'
    },
    {
      label: 'ouroboros',
      link: '/ouroboros/'
    },
    {
      label: 'ouroboros',
      link: '/ouroboros/'
    },
    {
      label: 'ouroboros',
      link: '/ouroboros/'
    },
    {
      label: 'ouroboros',
      link: '/ouroboros/'
    },
  ];

  title = "[Kaue Costa] @ kaox.tv - digital art . interaction . av performance ";
  chapter = "[Kaue Costa] ";
  textAbout = "is an interdisciplinary artist and coder. He creates experiences of live cinema, audiovisual performances, and art installations, utilizing code, audio/music/sound, light and interaction. He incorporates geometry, code + technology, natural systems, structure, phenomenon, and generative processes into his work.";

  constructor() { }

  ngOnInit() {
  }


  toggleMenu(type: string):void {

    switch (type) {
      case 'tokens':
        this.toggleTokens();
        break;

      case 'extra':
        this.toggleExtra();
        break;

      case 'about':
        this.toggleAbout();
        break;


      default:
        break;
    }

  }




  toggleTokens(): void {

    this.showTokens = !this.showTokens;
    console.log("abrindo TOKENS");

  }

  toggleExtra(): void {

    this.showLinks = !this.showLinks;
    console.log("abrindo LINKS");

  }

  toggleAbout(): void {

    this.showAbout = !this.showAbout;
    console.log("abrindo ABOUT");

  }

}
