import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const SELECT_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxCheckboxComponent),
  multi: true,
};

@Component({
  selector: 'ox-checkbox',
  templateUrl: './ox-checkbox.component.html',
  styleUrls: ['./ox-checkbox.component.scss'],
  providers: [SELECT_INPUT_VALUE_ACCESSOR],
})
export class OxCheckboxComponent implements ControlValueAccessor {
  inputValue: any[] = [];

  @Input() internalDisabled = false;
  @Input() label = '';
  @Input() requerido = false;

  @Input() set itens(value: any[]) {
    this.options = [];
    value.forEach((v) => {
      this.options.push({
        value: v.nome,
        checked: false,
      });
    });
  }


  options: { value: string; checked: boolean }[] = [];

  onChange = (value: any) => { };
  onTouched = () => { };

  selected(event: any, index: number): void {
    this.options[index].checked = event.target.checked ? true : false;
    const response = this.options.filter((v) => v.checked).map((v) => v.value);
    this.onChange(response);
  }

  writeValue(value: any[] = []): void {
    this.options = this.options.map((item) => {
      value?.indexOf(item.value) >= 0 ? (item.checked = true) : (item.checked = false);
      return item;
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(internalDisabled: boolean): void {
    this.internalDisabled = internalDisabled;
  }
}
