<div class="base-editor p-2">
  <div class="row p-0">
    <div class="col-8">
      <ox-select
        id="baseRoot"
        label="Base"
        [(ngModel)]="base"
        [itens]="selectBases"
        (ngModelChange)="loadData()"
      ></ox-select>
    </div>
    <div class="col-2"></div>
    <div class="col-2">
      <ox-button
        class="mt-5 form-group"
        label="EDITOR"
        (onClick)="toggleShow()"
      ></ox-button>
    </div>
    <ng-container *ngIf="showEditor">
      <div class="col-12 m-1 form-group" *ngFor="let item of listaBases">
        <app-collection-cms
          [item]="item"
          [base]="base"
          (openCollection)="open($event)"
        ></app-collection-cms>
      </div>
      <div class="col-12 m-1 form-group">
        <app-collection-cms [base]="base" [item]="null"></app-collection-cms>
      </div>
    </ng-container>
  </div>
</div>
