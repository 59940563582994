import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const SIMPLE_RADIO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxRadioComponent),
  multi: true,
};

@Component({
  selector: "ox-radio",
  templateUrl: "./ox-radio.component.html",
  styleUrls: ["./ox-radio.component.scss"],
  providers: [SIMPLE_RADIO_VALUE_ACCESSOR],
})
export class OxRadioComponent implements ControlValueAccessor {
  internalInputValue: any = "";
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @Input() label = "";
  @Input() tamanho = 400;
  @Input() placeholder = "";
  @Input() id: string = null;
  @Input() requerido: boolean = false;

  @Input() itens = [
    { valor: "s", nome: "Sim" },
    { valor: "n", nome: "Não" },
  ];

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  feedback_id(): string {
    if (this.id) {
      return "feedback_" + this.id;
    } else {
      return null;
    }
  }
}
