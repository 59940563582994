import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.scss']
})
export class ControllerComponent implements OnInit {

  formLogin = [
    {
      nome: "email",
      label: "login",
      obrigatorio: true,
      tipo: "INPUT",
      form: true,
    },
    {
      nome: "password",
      label: "password",
      obrigatorio: true,
      tipo: "PASSWORD",
      form: true,
    },
  ];

  showLogin: boolean = false;
  email: string = null;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  toggleLogin(): void {

    this.showLogin = !this.showLogin;

  }

  login(event: any): void {


    console.log(event);

    this.email = event.email;

    this.authService.SignIn(event.email, event.password);


  }

  forgotPassword(): void {

    if (this.email !== null) {
      this.authService.ForgotPassword(this.email);
    }

  }

  signOut() {
    this.authService.SignOut();
  }

}
