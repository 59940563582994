<div class="main px-5 pt-5" style="background-color: #666; min-height: 100px">
  <div class="row">
    <div class="col-4">
      <h1>
        <p>[ADMIN CMS] v0.3</p>
      </h1>
    </div>
    <div class="col-4 mt-2"></div>
    <div class="col-4 d-inline text-right">
      <!-- <ox-button
        class="m-3"
        [label]="btn.name"
        (onClick)="eventoBotao(btn.id)"
        *ngFor="let btn of buttons"
      ></ox-button> -->
      <hr class="my-3" />
    </div>
  </div>
</div>

<div class="w-100 row p-2">
  <div class="col-2">
    <div
      class="p-3"
      style="background-color: rgba(102, 102, 102, 0.618)"
      *ngIf="pronto"
    >
      <h2>COLLECTION:</h2>
      <ox-form
        [configuracao]="configBase"
        [dados]="dadosBASE"
        [tamanhoColuna]="'col-12'"
        (gravar)="saveForm($event)"
        [gravarLabel]="dadosBASE?.path ? 'UPDATE' : 'CREATE'"
      ></ox-form>

      <hr />

      <ox-button
        class="mt-5"
        label="CLEAR"
        (onClick)="clearForm()"
        *ngIf="dadosBASE"
      ></ox-button>
      <ox-button
        class="mt-5"
        label="CLONE"
        (onClick)="cloneForm()"
        *ngIf="dadosBASE"
      ></ox-button>
      <ox-button
        class="mt-5"
        label="DELETE"
        (onClick)="deleteForm()"
        *ngIf="dadosBASE"
      ></ox-button>
    </div>
    <div *ngFor="let item of listaBases" class="w-100 m-1 row">
      <div class="col-9 form-group px-4 ml-n2" style="font-size: 1.2em">
        <ox-button [label]="item.nome" (onClick)="readForm(item)"></ox-button>
      </div>
    </div>
  </div>

  <div class="col-1">
    <ng-container *ngIf="listaInputs">
      <div *ngFor="let item of listaInputs" class="w-100 m-1 row">
        <div class="col-12 form-group" style="font-size: 1.2em">
          <ox-button
            [label]="item.name"
            (onClick)="readInput(item)"
          ></ox-button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-4 p-3" style="background-color: rgba(102, 102, 102, 0.618)">
    <h2>{{ dadosITEM?.path ? dadosITEM?.label : "NEW ITEM" }}</h2>
    <ox-form
      [configuracao]="configFormMaster"
      [dados]="dadosITEM"
      [path]="dadosITEM?.path"
      [tamanhoColuna]="'col-6'"
      (gravar)="saveInput($event)"
      [gravarLabel]="dadosITEM?.path ? 'UPDATE' : 'CREATE'"
    ></ox-form>
    <hr />
    <ox-button
      class="mt-5"
      label="CLEAR"
      (onClick)="clearInput()"
      *ngIf="dadosITEM"
    ></ox-button>
    <ox-button
      class="mt-5"
      label="CLONE"
      (onClick)="cloneInput()"
      *ngIf="dadosITEM"
    ></ox-button>
    <ox-button
      class="mt-5"
      label="DELETE"
      (onClick)="deleteInput()"
      *ngIf="dadosITEM"
    ></ox-button>
  </div>

  <div class="col-5">
    <div
      class="w-100 ml-3 p-3"
      style="background-color: rgba(102, 102, 102, 0.618)"
    >
      <h2>DATA: {{ dadosBASE?.nome }}</h2>
    </div>
  </div>
</div>
