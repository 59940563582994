import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import {
  Observable
} from 'rxjs';

export interface Tokens {
  artifact_uri: string;
  contract: string;
  creators: []
  decimals: 0
  description: string;
  formats: []
  is_transferable: boolean;
  level: number;
  name: string;
  network: string;
  supply: string;
  symbol: string;
  tags: []
  thumbnail_uri: string;
  timestamp: string;
  token_id: number;
  token_info: Object;
  transfered: number;
}

@Injectable({
  providedIn: 'root'
})
export class BetterCallDevService {

  root = 'https://api.better-call.dev/';
  api = 'v1';
  url = this.root + this.api;

  contrato = 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton';

  headers = new HttpHeaders();
  network: string;

  constructor(private http: HttpClient) {

    this.headers.append('Content-Type', 'application/json');
    this.network = 'mainnet';

  }


  //HEAD
  getNetworks(): Observable < Object > {

    return this.http.get(`${this.url}/head`);

  }

  setNetwork(network: string): void {

    this.network = network;

  }


  //ACCOUNTS


  // Account address (starting with tz or KT)
  //OK
  getAccountInfo(address: string): Observable < Object > {

    return this.http.get(`${this.url}/account/${this.network}/${address}`);

  }


  // QUERY PARAMETERS
  // offset	integer > Offset
  // size	integer [ 0 .. 10 ] > Requested count
  // contract	string > Contract address
  // sort_by string Enum: "level" "balance" > Field using for sorting
  //OK
  getAccountTokenBalance(address: string, contract: string): Observable < Object > {

    let size = '6';


    const params = new HttpParams()
      .set('size', size)
      .set('contract', contract);

    return this.http.get(`${this.url}/account/${this.network}/${address}/count`);

  }


  //OK
  getAccountMetadata(address: string): Observable < Object > {

    return this.http.get(`${this.url}/account/${this.network}/${address}/metadata`);

  }


  // getAccountBalanceHistory(address: string): Observable<Object> {

  //   return this.http.get(`${this.url}/account/${this.network}/${address}/balance_history`);

  // }



  // getAccountReport(address: string): Observable<Object> {

  //   return this.http.get(`${this.url}/account/${this.network}/${address}/report`);

  // }



  /// TOKENS

  // QUERY PARAMETERS
  //size	integer <= 10 >> Requested count
  //offset integer <= 10 >> Offset
  //max_level	integer >> Maximum token`s creation level (less than or equal)
  //min_level	integer >> Minimum token`s creation level (greater than)
  //creator	string <= 25 characters >> Creator name
  //contract string 36 characters >> KT address
  //token_id integer >> Token ID
  searchTokens(contract: string, creator: string): Observable < Object > {

    let size = '10';

    const params = new HttpParams()
      .set('size', size)
      .set('creator', creator)
      .set('contract', contract);

    return this.http.get(`${this.url}/tokens/${this.network}/metadata`, {
      params
    });

  }

  getTokenInfo(contract: string, tokenId: string): Observable < Object > {

    let size = '4';

    const params = new HttpParams()
      .set('size', size)
      .set('token_id', tokenId)
      .set('contract', contract);

    return this.http.get(`${this.url}/tokens/${this.network}/metadata`, {
      params
    });

  }

  ////


  //CONTRACTS

  // Contract address (starting with KT)
  getContract(address: string): Observable < Object > {

    return this.http.get(`${this.url}/contracts/${address}`);

  }

  getContractStorage(address: string): Observable < Object > {

    return this.http.get(`${this.url}/contracts/${address}/storage`);

  }

  getContractCount(): Observable < Object > {

    return this.http.get(`${this.url}/contracts/count`);

  }



  ////////

  returnGetFilter(path: string, obj: any) {

    return this.http.get(`${this.url}${path}/`); //.map(res => res.json());


  }

  returnPost(path: string, obj: any) {

    return this.http.post(`${this.url}${path}/`, obj); //.map(res => res.json());

  }

  returnPut(path: string, obj: any) {

    return this.http.put(`${this.url}${path}/`, obj); //.map(res => res.json());

  }



}
