import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScrollToAnimationEasing, ScrollToConfigOptions, ScrollToOffsetMap, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

import { CustomColorPaletteService } from 'src/app/services/custom-color-palette.service';
import { HostListener } from "@angular/core";
import { ScrollToEvent } from '@nicky-lenaers/ngx-scroll-to/lib/scroll-to-event.interface';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-base-render',
  templateUrl: './base-render.component.html',
  styleUrls: ['./base-render.component.scss']
})
export class BaseRenderComponent implements OnInit, OnChanges {

  screenHeight: number;
  screenWidth: number;

  @Input() content: any;

  COLOR_CUSTOM = '#9B4080';
  COLOR_WHITE = '#FCFCFC';
  COLOR_BLACK = '#222222';

  actualClip : number = 0;

  bgoPivot : any;
  layer1Pivot : any;
  layer2Pivot : any;
  contentPivot : any;
  overlayPivot : any;


    constructor(
      private scrollToService: ScrollToService,
      private scroller: ViewportScroller,
      private customColorService: CustomColorPaletteService,
      )
    {
    this.onResize();
    this.customColorService.defineColors(
      this.COLOR_CUSTOM,
      this.COLOR_WHITE,
      this.COLOR_BLACK
    );
    this.customColorService.buildColorPalette();
   }

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    }

  ngOnInit(): void {

    // Set the initial positions of the layers
    this.bgoPivot = '0';
    this.layer1Pivot = '0';
    this.layer2Pivot = '0';

    // Listen for mouse movement and update the positions of the layers
    document.addEventListener('mousemove', (event) => {
      let mouseX = event.clientX;
      let mouseY = event.clientY;
      let centerX = window.innerWidth / 2;
      let centerY = window.innerHeight / 2;
      let deltaX = mouseX - centerX;
      let deltaY = mouseY - centerY;

      console.log(deltaX, deltaY);


      this.bgoPivot = (-deltaY * 0.5) + 'px';
      this.layer1Pivot = (-deltaY * 0.75) + 'px';
      this.layer2Pivot = (-deltaY * 1) + 'px';
      this.contentPivot = (deltaX * 0.5) + 'px';
      this.overlayPivot = (deltaX * 0.25) + 'px';
    });

  }

  ngOnChanges(): void {




  }
  triggerToNext(type: any) {

    this.actualClip ++;
    let target = this.content[this.actualClip % this.content.length].title;
    this.triggerToTarget(target);

    console.log(this.scroller.getScrollPosition(), this.screenHeight, this.screenWidth);


  }

  triggerToTarget(target: string) {

    const config: ScrollToConfigOptions = {
      target,
      duration: 500,
      easing: 'easeOutQuint',
      offset: 20
    };

    // this.scrollToService.scrollTo(config);
    this.scroller.scrollToAnchor(target);
  }

// easeInQuad
// easeOutQuad
// easeInOutQuad
// easeInCubic
// easeOutCubic
// easeInOutCubic
// easeInQuart
// easeOutQuart
// easeInOutQuart
// easeInQuint
// easeOutQuint
// easeInOutQuint
// easeOutElastic

  triggerScrollTo() {

    /**
     * @see NOTE:1
     */
    const config: ScrollToConfigOptions = {
      container: 'custom-container',
      target: 'destination',
      duration: 650,
      easing: 'easeOutElastic',
      offset: 20
    };

    this.scrollToService.scrollTo(config);
  }

  triggerScrollToOffsetOnly(offset: number = 0) {

    const config: ScrollToConfigOptions = {
      offset
    };

    this.scrollToService.scrollTo(config);
  }

}
