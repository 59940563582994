<div id="credit">
  kaox.tv: some rights reserved : kaue costa / terra sem mal / brazil / since
  2007 - 2022
  <a
    class=""
    rel="license"
    href="http://creativecommons.org/licenses/by-nc-nd/3.0/"
    target="_blank"
  >
    <img
      alt="Creative Commons License"
      style="border-width: 0"
      src="http://i.creativecommons.org/l/by-nc-nd/3.0/80x15.png"
  /></a>
  <!-- // <a href="http://www.kaox.tv/portfolio/rss.php?lang=en_US" rel="nofollow">RSS
    <img src="http://www.kaox.tv/portfolio/zp-core/images/rss.gif" alt="RSS Feed"></a>
       // -->
</div>
<app-controller></app-controller>
