<div class="form-group">
  <label class="d-flex" [for]="id"
    >{{ label }}
    <div *ngIf="requerido" class="campo-obrigatorio pl-1"></div>
  </label>

  <div class="row">
    <div class="col">
      <input
        id="nome"
        (keydown.enter)="addItem()"
        (keydown.delete)="removeItem()"
        [(ngModel)]="nomeInput"
      />
    </div>
    <div class="col">
      <input
        id="valor"
        (keydown.enter)="addItem()"
        (keydown.delete)="removeItem()"
        [(ngModel)]="valorInput"
      />
    </div>
  </div>

  <!-- <input id="internalInputValue" [(ngModel)]="internalInputValue" /> -->

  <div class="row">
    <div class="col tag" *ngFor="let item of itens" (click)="editItem(item)">
      {{ item[campoTexto] }}
    </div>
  </div>
  <!--  -->
  <!--  <select class="form-control margem-form" [id]="id" autocomplete="off" [(ngModel)]="inputValue">-->
  <!--    <option *ngIf="! permiteVazio" selected hidden style='display: none' value=''></option>-->
  <!--    <option *ngIf="permiteVazio" selected value=''></option>-->
  <!--    <option *ngFor="let item of itens" [ngValue]="item[campoChave]">{{item[campoTexto]}}</option>-->
  <!--  </select>-->
  <label [id]="feedback_id()"></label>
</div>
