import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormService, FormTemplate } from 'src/app/services/form.service';

import { FirebaseApiService } from 'src/app/services/firebase.service';

const OX_COLLECTION_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OxCollectionComponent),
  multi: true,
};

@Component({
  selector: 'ox-collection',
  templateUrl: './ox-collection.component.html',
  styleUrls: ['./ox-collection.component.scss'],
  providers: [OX_COLLECTION_VALUE_ACCESSOR],
})
export class OxCollectionComponent implements ControlValueAccessor {
  internalInputValue: any = '';
  @Input() internalDisabled = false;
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @Input() label = '';
  @Input() placeholder = '';
  @Input() id: string = null;
  @Input() path: string = null;
  @Input() maxlength = '999';
  @Input() multiline = false;

  @Input() mask;
  @Input() type = 'text';
  @Input() requerido = false;
  @Output() open = new EventEmitter();

  showDelete: boolean;

  configBase: FormTemplate[];
  loaded: boolean;
  public dadosBASE: any;
  showEdit: boolean;


  constructor(private formService: FormService, public firebase: FirebaseApiService) {

    formService.getCollectionForm().subscribe((res) => {

      this.configBase = res;
      this.loaded = true;

    });



  }

  get inputValue(): any {
    return this.internalInputValue;
  }

  set inputValue(value: any) {
    if (value !== this.internalInputValue) {
      this.internalInputValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    this.internalInputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public onChange(event: any): void { }

  feedback_id(): string {
    if (this.id) {
      return 'feedback_' + this.id;
    } else {
      return null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.internalDisabled = isDisabled;
  }



  setupCollection(): void {

    this.showEdit = true;

  }

  saveCollection(item: any): void {

    console.log(item, this.path);

    if (this.dadosBASE?.path) {
      // this.firestore.doc<any>(this.dadosBASE?.path).update(item);
      this.firebase.updateItem(this.dadosBASE?.path, item);
    } else {
      this.firebase.addItemToCollection(this.path + '/' + this.id, item).then((res) => {
        console.log('IITEEEMM', res);
        this.inputValue = res;
      });
    }

  }


  deleteCollection(): void {

    this.firebase.removeItem(this.dadosBASE?.path);
    this.showDelete = false;
    this.inputValue = null;

  }

  openCollection(): void {

    console.log(this.inputValue);
    this.open.emit(this.inputValue);
    // window.open(this.inputValue, '_blank');

  }

  toggleDelete(): void {

    this.showDelete = !this.showDelete;

  }

}
