import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Network {
  type: NetworkType;
  name?: string;
  rpcUrl?: string;
}

export enum NetworkType {
  EDONET = 'edonet',
  HEN = 'hen',
  MAINNET = 'mainnet',
  CARTHAGENET = 'carthagenet',
  DELPHINET = 'delphinet',
  CUSTOM = 'custom',
}

export namespace Network {
  export function values(): string[] {
    return Object.values(NetworkType).filter(
      (value) => typeof value === 'string'
    ) as string[];
  }

  export function getUrl(network: NetworkType): string {
    return getNetwork(network).rpcUrl;
  }

  export function getNetwork(network: NetworkType): Network {
    return {
      [NetworkType.EDONET]: {
        type: NetworkType.EDONET,
        name: 'RPC EDONET',
        rpcUrl: 'https://api.tez.ie/rpc/edonet', //https://api.tez.ie/rpc/carthagenet',
      },
      [NetworkType.HEN]: {
        type: NetworkType.HEN,
        name: 'RPC HIC ET NUNC',
        rpcUrl: 'https://api.tez.ie/rpc/edonet', //https://api.tez.ie/rpc/carthagenet',
      },
      [NetworkType.MAINNET]: {
        type: NetworkType.MAINNET,
        name: 'Mainnet',
        rpcUrl: 'https://api.tez.ie/rpc/mainnet',
      },
      [NetworkType.CARTHAGENET]: {
        type: NetworkType.CARTHAGENET,
        name: 'Carthagenet',
        rpcUrl: 'https://api.tez.ie/rpc/edonet', //https://api.tez.ie/rpc/carthagenet',
      },
      [NetworkType.DELPHINET]: {
        type: NetworkType.DELPHINET,
        name: 'Delphinet',
        rpcUrl: 'https://api.tez.ie/rpc/delphinet',
      },
    }[network];
  }
}


@Injectable({
  providedIn: 'root'
})
export class TzKTApiService {

  root = 'https://api.mainnet.tzkt.io/';
  api = 'v1';
  url = this.root + this.api;

  contrato = 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton';

  headers = new HttpHeaders();

  constructor(private http: HttpClient) {

    this.headers.append('Content-Type', 'application/json');

  }


  //CONTRACTS

  // Contract address (starting with KT)
  getContract(address: string): Observable<Object> {

    return this.http.get(`${this.url}/contracts/${address}`);

  }

  getContractStorage(address: string): Observable<Object> {

    return this.http.get(`${this.url}/contracts/${address}/storage`);

  }

  getContractCount(): Observable<Object> {

    return this.http.get(`${this.url}/contracts/count`);

  }

  //ACCOUNTS


  // Account address (starting with tz or KT)
  getAccount(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}`);

  }


  getAccountContract(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}/contracts`);

  }




  getAccountOperations(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}/operations`);

  }



  getAccountMetadata(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}/metadata`);

  }


  getAccountBalanceHistory(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}/balance_history`);

  }



  getAccountReport(address: string): Observable<Object> {

    return this.http.get(`${this.url}/accounts/${address}/report`);

  }


  getAccountsCount(): Observable<Object> {

    return this.http.get(`${this.url}/account/count`);

  }

  ////////

  returnGetFilter(path:string, obj:any){

    return this.http.get(`${this.url}${path}/`); //.map(res => res.json());


  }

  returnPost(path:string, obj:any){

    return this.http.post(`${this.url}${path}/`, obj); //.map(res => res.json());

  }

  returnPut(path:string, obj:any){

    return this.http.put(`${this.url}${path}/`, obj); //.map(res => res.json());

  }



}


