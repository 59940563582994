import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ZenProject {

  query: string;
  total: number;
  version: string;
  rows: [{
    custom_data: string;
    date: string;
    desc: string;
    folder: string;
    id: string;
    sort_type: string;
    thumb: string;
    title: string;
    tags: [
      any
    ]
  }]
}

export interface ZenAlbumRaw {

  album_sortdirection: string;
  album_theme?:  string;
  closecomments?:  string;
  commentson?:  string;
  custom_data?:  string;
  date?:  string;
  desc?:  string;
  dynamic?:  string;
  folder?:  string;
  hitcounter?:  string;
  id?:  string;
  image_sortdirection?:  string;
  mtime?:  string;
  parentid?:  string;
  password?:  string;
  password_hint?:  string;
  place?:  string;
  search_params?:  string;
  show?:  string;
  sort_order?:  string;
  sort_type?:  string;
  subalbum_sort_type: string;
  thumb?:  string;
  title?:  string;
  total_value?:  string;
  total_votes?:  string;
  used_ips?:  string;
  user?:  string;

}

export interface ZenItem {

  query: string;
  total: number;
  version: string;
  rows: [{
    custom_data: string;
    date: string;
    desc: string;

    albumid: string;
    credit: string;
    filename: string;

    height: string;
    width: string;

    folder?: string;

    title: string;
    id: string;

    tags: [
      any
    ]
  }]
}


@Injectable({
  providedIn: 'root'
})
export class ZenKaoxApiService {

  root = 'https://kaox.tv/';
  api = 'api/';
  url = this.root + this.api;

  headers = new HttpHeaders();

  constructor(private http: HttpClient) {

    this.headers.append('Content-Type', 'application/json');

  }

  getAllProjects(): Observable<Object> {

    return this.http.get(`${this.url}listProjects.php`);

  }

  getAllTags(): Observable<Object> {

    return this.http.get(`${this.url}listTags.php`);

  }

  getAlbumData(id: any): Observable<Object> {

    return this.http.get(`${this.url}getAlbumData.php?id=${id}`);

  }

  getAlbum(id: any): Observable<Object> {

    return this.http.get(`${this.url}listContent.php?id=${id}`);

  }

  ////////

  returnGetFilter(path:string, obj:any){

    return this.http.get(`${this.url}${path}/`); //.map(res => res.json());


  }

  returnPost(path:string, obj:any){

    return this.http.post(`${this.url}${path}/`, obj); //.map(res => res.json());

  }

  returnPut(path:string, obj:any){

    return this.http.put(`${this.url}${path}/`, obj); //.map(res => res.json());

  }



}


