import {
  Component,
  OnInit
} from '@angular/core';
import {
  BetterCallDevService, Tokens
} from 'src/app/services/better-call-dev.service';
import {
  TzKTApiService
} from 'src/app/services/tz-kt.service';
import {
  ZenKaoxApiService
} from '../../services/zen-kaox-api.service'
import { galleryObj } from '../../components/gallery-view/gallery-view.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

  contract = 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton'; //HIC ET NUNC CONTRACT
  account = 'tz1XFHXtE9CXskkZmkwqytzZaKoQApvNxPhp';
  tokenId = '45820';
  tokens: Tokens[];

  gallery : galleryObj[];

  // https://better-call.dev/docs

  constructor(private sanitizer: DomSanitizer, public bcd: BetterCallDevService) {}

  ngOnInit() {

    this.bcd.searchTokens(this.contract, this.account).subscribe((res: Tokens[]) => {

      this.gallery = [];
      console.log("TOKENS:", res);
      this.tokens = res;

      this.tokens.forEach((tkns, index) => {

        this.gallery.push({

          id: index,
          name: tkns.name,
          desc: tkns.description,
          background:  this.sanitizer.bypassSecurityTrustResourceUrl(this.getIPFSThumb(tkns.artifact_uri)),

          date:  tkns.timestamp,
          tags: tkns.tags,
          custom_data:  tkns.contract,

          url: this.getIPFSThumb(tkns.artifact_uri)
        });

      });

      console.log(this.gallery);


    }, (err) => { console.log(err); });


  }


  getIPFSThumb(thumbnail_uri: string): string {

    let url = 'https://ipfs.io/ipfs/';

    url = url + thumbnail_uri.split('//')[1];

    return url;
  }

}
