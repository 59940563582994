import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { FormTemplate } from "src/app/services/form.service";

@Component({
  selector: "ox-form",
  templateUrl: "./ox-form.component.html",
  styleUrls: ["./ox-form.component.scss"],
})
export class OxFormComponent implements OnInit, OnChanges {
  @Input() dados: any = null;
  @Input() path: string = null;
  @Input() configuracao: FormTemplate[];
  @Input() editando = false;
  @Input() tamanhoColuna = 6;

  @Input() gravarIcone = "";
  @Input() gravarLabel = "";

  @Output() gravar = new EventEmitter();
  @Output() executar = new EventEmitter();
  @Output() action = new EventEmitter();


  form: FormGroup;
  layoutForm: {};

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.iniciarForm();

    if (this.dados !== null) {
      this.preencherDados();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.iniciarForm();

    if (this.dados !== null) {
      this.preencherDados();
    }
  }

  iniciarForm(): void {
    let campos = {};

    this.configuracao.forEach((campo) => {
      if (campo.form) {
        if (campo.obrigatorio) {
          campos[campo.nome] = [campo.valor, [Validators.required]];
        } else {
          campos[campo.nome] = [campo.valor, []];
        }
      }
    });

    this.form = this.fb.group(campos);
  }

  preencherDados(): void {
    Object.entries(this.form.value).forEach((item) => {

      console.log('preencherDados', item, this.configuracao);

      if (this.dados?.hasOwnProperty(item[0])) {
        this.form.get(item[0]).setValue(
          typeof this.dados[item[0]] === 'object' ? JSON.stringify(this.dados[item[0]]) : this.dados[item[0]]
        );
      }
    });
  }


  prepararDadosGravacao(): any {

    let gravando: any = {};

    Object.entries(this.form.value).forEach((item) => {

      console.log(item);

      if (this.form.get(item[0]).value !== null) gravando[item[0]] = this.form.get(item[0]).value;
      // gravando[item[0]] = this.form.get(item[0]).value ? this.form.get(item[0]).value : '';

      // this.dados[item[0]] = this.form.get(item[0]).value;
    });

    return gravando;
    // return this.dados;
  }

  habilitarCampo(campo: any): boolean {
    let editar = false;

    if (campo.editar !== null) {
      editar = campo.editar;
    }

    return campo.disabled || (editar && this.editando);
  }

  gravarForm(): void {
    this.gravar.emit(this.prepararDadosGravacao());
  }

  public getItensEnum(campo: any): { chave: any; texto: string }[] {
    const itens = [];
    Object.keys(campo.enum).forEach((opcao) => {
      itens.push({ chave: campo.enum[opcao], texto: opcao });
    });

    return itens;
  }



  send(type: string, value: any): void {

    // console.log(' O P C', type, value);
    this.action.emit({ type, value });

  }
}
