import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ZenKaoxApiService, ZenProject } from 'src/app/services/zen-kaox-api.service';

import { FirebaseApiService } from 'src/app/services/firebase.service';

export interface ProjectOX {
  id: string;
  order?: string;
  title?: string;
  thumb?: string;
  folder?: string;
  url?: SafeResourceUrl;
  bgo?: SafeResourceUrl;
  tags?: [
    any
  ];
}

@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss']
})
export class GridMenuComponent implements OnInit {

  projects: ProjectOX[] = [];
  loaded: boolean;

  @Output() fechar = new EventEmitter();
  @Input() item: any;

  @Input() modoFirebase = true;

  albumPath = "http://kaox.tv/cache/";
  firebasePath = 'PROJECTS/hqL3P6H4poWPPfqXSLj0';

  constructor(public firebase: FirebaseApiService, public api: ZenKaoxApiService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    console.log("TESTE");

    this.loadData();


    this.api.getAllTags().subscribe((res) => {

      console.log("TAGSS:  ", res);

    });


    this.api.getAlbum(37).subscribe((res) => {

      console.log("CNTSSS:  ", res);

    });


  }

  loadData(): void {


    if (this.modoFirebase) {

      this.firebase.getC(this.firebasePath, 'albums').subscribe((base) => {

        console.log('BASeeeeeeeeeeeeeeeeeeeeeEE', base);
        this.projects = [];

        base.forEach(row => {

          let url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.albumPath}/${row.folder}/${row.thumb}_250.jpg`);

          this.projects.push(
            {
              id: row.id,
              title: row.title,
              thumb: row.thumb,
              folder: row.folder,
              order: row.sort_order,
              url,
              // bgo: string;
              tags: row.tags,
            }
          );

        });

        this.projects = this.projects.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));

        this.loaded = true;


      });

    } else {

      this.projects = [];

      this.api.getAllProjects().subscribe((res: ZenProject) => {

        console.log(res);
        res.rows.forEach(row => {

          let url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.albumPath}/${row.folder}/${row.thumb}_250.jpg`);

          this.projects.push(
            {
              id: row.id,
              title: row.title,
              thumb: row.thumb,
              folder: row.folder,
              url,
              // bgo: string;
              tags: row.tags,
            }
          );

        });

        this.loaded = true;
        console.log(this.projects);

        // const imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${img.url}`);
      });

    }



  }


  openProject(id: any): void {

    console.log(id);

  }

}
