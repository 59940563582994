<p>menu works!</p>

<div id="sidebar">

	<div id="albums" *ngIf="loaded">
			<div class="album" *ngFor="let item of projects">
				<div class="thumb">
					<img [src]="item.url"
            [alt]="item.title">
				</div>
				<div class="albumdesc">
					<a (click)="openProject(item.id)"
            [title]="item.title">
            {{item.title}}</a>
				</div>
			</div>
	</div>



</div>
